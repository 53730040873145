import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { useLocation, useParams } from "react-router";
import classes from "./BlogDetail.module.css";
import HTMLReactParser from "html-react-parser";
import Moment from "moment/moment";
import {ScrollTop} from "../../../util/commonUtil";

const BlogDetail = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const { name, id } = useParams();
  const [blogData, setBlogData] = useState(state);

  useEffect(() => {
    ScrollTop();
    GetBlog();
  }, []);

  const GetBlog = async () => {
    const response = await appContext.FetchHandler(
        "blog/Detail",
        "post",
        null,
        { id: id },
    );

    if (response.ok) {
      setBlogData(response.data);
    }
  };

  return (
    <div className="pageContainer">
      {blogData && (
          <div className={classes.main}>
            <div className={classes.mainTop}>
              <h2>{blogData.name}</h2>
              <p>{Moment(blogData.updatedDate).format("DD/MM/YYYY")}</p>
            </div>
            <div className={classes.mainBottom}>
              <img src={blogData.mediaUrl} alt={blogData.name}/>
              <p>{blogData.shortDescription}</p>
              {blogData.longDescription &&
                  HTMLReactParser(blogData.longDescription)}
            </div>


          </div>
      )}
    </div>
  );
};

export default BlogDetail;
