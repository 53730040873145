import React, { Fragment, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "./mainSlider.css";
import { AppContext } from "../../../context/app-context";

const MainSlider = ({ data }) => {
  const appContext = useContext(AppContext);
  return (
    <Fragment>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination]}
        className="mainSlider"
      >
        {data.map((x, i) => {
          return (
            <SwiperSlide key={i}>
              <Link
                to={x.linkUrl}
                state={{
                  data: data.filter((m) => m.id !== x.id),
                  selectedData: x,
                }}
              >
                <img
                  src={appContext.isMobile ? x.mobileMediaUrl : x.mediaUrl}
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                {/*<h2>{x.name}</h2>*/}
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Fragment>
  );
};

export default MainSlider;
