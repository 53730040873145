import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import {
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
} from "../../../util/commonUtil";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { CommonCrudForm } from "../../../components/admin/commonCrudForm/CommonCrudForm";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

export const SubCategory = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const pageTitle = ` ${state.name} - Alt Kategorileri`;
  const apiUrl = "category";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "350px",
      cell: (row) => {
        return (
          <div className="dataTableProcess">
            <Link
              className="dataTableProcessOrange"
              to="/admin/category/subCategory"
              state={row}
            >
              Alt Kategoriler
            </Link>
            {DataTableProcess(
              row,
              (event) => DeleteHandler(event),
              () => EditHandler(row),
              null,
            )}
          </div>
        );
      },
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      filterName: "name",
      sortable: true,
      width: "auto",
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "100px",
    },
    {
      name: "Sıra",
      selector: (row) => row.index,
      sortable: true,
      width: "100px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        `${apiUrl}/subCategoryList`,
        "post",
        null,
        { parentId: state.id },
        "admin",
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState, state]);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      const formData = new FormData(document.getElementById("form"));
      if (formInput.id !== undefined) formData.append("id", formInput.id);

      formData.append("parentId", state.id);

      if (res.isConfirmed) {
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          `${apiUrl}`,
          method,
          "formData",
          formData,
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          `${apiUrl}`,
          "delete",
          null,
          { id: event.target.id },
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            setFormInput();
            setShowModal(true);
          }}
        >
          Alt Kategori Ekle
        </button>
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Ekle `}
        >
          <CommonCrudForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
            isMedia={false}
            hideShort={false}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
