import React, { Fragment, useContext, useEffect, useState } from "react";
import { ConvertFileBase64, EntityStatusData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";
import {
  SwalErrorMessage,
  SwallApprove,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { AppContext } from "../../../context/app-context";
import classes from "./AddComment.module.css";
import { CheckFormValid, Validation } from "../../ui/input/Validation";
import GiveStar from "../../ui/giveStar/GiveStar";
import { FileUpload } from "../../ui/fileUpload/FileUpload";
import { AddCompany } from "../addCompany/AddCompany";

export const AddComment = ({ setShowModal, companyId, setCrudState }) => {
  const appContext = useContext(AppContext);

  const [formInput, setFormInput] = useState({
    company: 0,
    companyData: null,
    rate: 5,
    title: null,
    comment: null,
    youtubeUrl: null,
    files: [],
    companyId: companyId != undefined || companyId != null ? companyId : null,
    name: null,
    category: null,
    phoneNumber: null,
    email: null,
    country: null,
    city: null,
    shortDescription: null,
    address: null,
  });

  const [formInputIsValid, setFormInputIsValid] = useState({
    rate: { status: true, message: "Puan: Zorunludur." },
    title: { status: false, message: "Başlık: Zorunludur." },
    comment: { status: false, message: "Yorum: Zorunludur." },
    companyData: {
      status: companyId != undefined || companyId != null,
      message: "Marka: Zorunludur.",
    },
    name: { status: true, message: "Marka Ad: Zorunludur." },
    category: { status: true, message: "Kategori: Zorunludur." },
  });

  useEffect(() => {}, [formInput]);

  const GetComData = () => {
    const _tempData = [];
    for (const item of appContext.companyData) {
      _tempData.push({ value: item.id, label: item.name });
    }

    return _tempData;
  };

  const SubmitHandler = async (event) => {
    event.preventDefault();
    let isValid = { status: false, message: "Hata" };
    isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }
    const formData = new FormData(document.getElementById("form"));
    formData.append(
      "companyId",
      formInput.companyId == null
        ? "00000000-0000-0000-0000-000000000000"
        : formInput.companyId,
    );
    formData.delete("rate");
    formData.append("rate", formInput.rate);

    formData.delete("files");
    formInput.files.forEach((x, i) => {
      formData.append(`files`, x.file);
    });

    if (formInput.category !== undefined && formInput.category?.value != null) {
      formData.delete("category");
      formData.append(`category[value]`, formInput?.category?.value);
      formData.append(`category[label]`, formInput?.category?.label);
    }

    await SwallApprove(null, "Yorumunuz kaydedilecek.").then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          formInput.company === 0 ? "comment" : "comment/AddWithCompany",
          "post",
          "formData",
          formData,
        );

        if (response.ok) {
          event.target.reset();
          setShowModal(false);
          SwalSuccessMessage(response.data);
        }
      }
    });
  };
  return (
    <Fragment>
      <form
        id="form"
        className="form"
        onSubmit={async (event) => {
          event.preventDefault();
          await SubmitHandler(event);
        }}
      >
        <h2>Memnunsan Yaz</h2>

        {(companyId === "undefined" || companyId == null) && (
          <Fragment>
            <Input
              label="Marka"
              type="radioGrup"
              id="company"
              option={[
                {
                  id: Math.round(Math.random() * 99999),
                  value: 0,
                  text: "Marka Seç",
                  checked:
                    formInput != null &&
                    formInput.company != null &&
                    formInput.company === 0,
                },
                {
                  id: Math.round(Math.random() * 99999),
                  value: 1,
                  text: "Marka Ekle",
                  checked:
                    formInput != null &&
                    formInput.company != null &&
                    formInput.company === 1,
                },
              ]}
              input={{
                name: "company",
                required: "required",
                onChange: (event) => {
                  const value = parseInt(event.target.value);
                  if (value === 0) {
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      companyData: {
                        status: false,
                        message: "Marka: Zorunludur.",
                      },
                      name: { status: true, message: "Marka Ad: Zorunludur." },
                      category: {
                        status: true,
                        message: "Kategori: Zorunludur.",
                      },
                    }));
                  } else {
                    setFormInputIsValid((prev) => ({
                      ...prev,
                      companyData: {
                        status: true,
                        message: "Marka: Zorunludur.",
                      },
                      name: { status: false, message: "Marka Ad: Zorunludur." },
                      category: {
                        status: false,
                        message: "Kategori: Zorunludur.",
                      },
                    }));
                  }

                  setFormInput((prev) => ({
                    ...prev,
                    company: value,
                    companyId: null,
                    companyData: null,
                  }));
                },
              }}
            />
            {formInput.company === 0 ? (
              <div style={{ display: "flex" }}>
                <Input
                  label="Marka"
                  type="selectSearch"
                  isMulti={false}
                  option={GetComData()}
                  style={{ width: "100%" }}
                  value={
                    formInput != null &&
                    formInput.companyData != null &&
                    formInput.companyData
                  }
                  input={{
                    id: "companyData",
                    onChange: (event, action) => {
                      setFormInputIsValid((prev) => ({
                        ...prev,
                        companyData: { status: true, message: "" },
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        companyData: event,
                        companyId: event.value,
                      }));
                    },
                  }}
                />
              </div>
            ) : (
              <AddCompany
                setShowAddCompanyModal={appContext.setShowAddCompanyModal}
                formInput={formInput}
                setFormInput={setFormInput}
                formInputIsValid={formInputIsValid}
                setFormInputIsValid={setFormInputIsValid}
              />
            )}
          </Fragment>
        )}

        {/*<div className={classes.starBox}>*/}
        {/*  <label>Puanınız</label>*/}
        {/*  <GiveStar*/}
        {/*    setFormInput={setFormInput}*/}
        {/*    setFormIsValid={setFormInputIsValid}*/}
        {/*  />*/}
        {/*  {formInput.rate != null && !formInputIsValid.rate.status && (*/}
        {/*    <span className={classes.error}>*/}
        {/*      {formInputIsValid.rate.message}*/}
        {/*    </span>*/}
        {/*  )}*/}
        {/*</div>*/}

        <Input
          label="Başlık"
          type="input"
          style={{ width: "100%" }}
          validation={formInput?.title != null && formInputIsValid.title}
          input={{
            id: "title",
            type: "text",
            value:
              formInput != null && formInput.title != null
                ? formInput.title
                : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                title: Validation("Başlık", value, {
                  isRequired: true,
                  min: 3,
                  max: 50,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                title: value,
              }));
            },
          }}
        />
        <Input
          label="Yorumunuz"
          type="textArea"
          style={{ width: "100%" }}
          validation={formInput?.comment != null && formInputIsValid.comment}
          input={{
            id: "comment",
            type: "text",
            value:
              formInput != null && formInput.comment != null
                ? formInput.comment
                : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                comment: Validation("Yorumunuz", value, {
                  isRequired: true,
                  min: 10,
                  max: 1500,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                comment: value,
              }));
            },
          }}
        />

        <Input
          label="Ürün Referans Linki"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "youtubeUrl",
            type: "text",
            value:
              formInput != null && formInput.youtubeUrl != null
                ? formInput.youtubeUrl
                : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInput((prev) => ({
                ...prev,
                youtubeUrl: value,
              }));
            },
          }}
        />
        <FileUpload formInput={formInput} setFormInput={setFormInput} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="submit"
            className="btnSave"
            style={{ backgroundColor: "#FF0000" }}
          >
            Gönder
          </button>
        </div>
      </form>
    </Fragment>
  );
};
