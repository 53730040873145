import React, { Fragment, useContext, useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import classes from "./SidebarClient.module.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/app-context";
import { ScrollTop } from "../../../util/commonUtil";

export const SidebarClient = (props) => {
  const appContext = useContext(AppContext);
  const [pathName, setPathName] = useState();
  const { toggleSidebar } = useProSidebar();

  const MenuHandler = (pathName) => {
    setPathName(pathName);
    toggleSidebar();
  };

  const MenuItemHandler = (url, text, data) => {
    return (
      <MenuItem
        active={pathName === url}
        component={<Link to={url} state={data} />}
        onClick={() => MenuHandler(url)}
      >
        <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
          <span className={classes.menuItemContentText}>{text}</span>
        </div>
      </MenuItem>
    );
  };

  return (
    <Fragment>
      <Sidebar
        defaultCollapsed={false}
        breakPoint="xxl"
        backgroundColor="#f5f5f5"
        rootStyles={{
          border: "none",
          backgroundColor: "#FF0000",
          color: "#fff",
          boxShadow: "0 2px 15px rgba(0, 0, 0, .3)",
          height: "100vh",
        }}
      >
        <Link to="/" className={classes.logo} onClick={() => toggleSidebar()}>
          <img
            src="/assets/img/logo.png"
            alt="logo"
            style={{ width: "150px" }}
          ></img>
        </Link>

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // if (level === 0) {

              // }
              return {
                color: disabled ? "#ccc" : active ? "#fff" : "#043657",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#FF0000",
                },
                backgroundColor: active ? "#FF0000" : "#f55858",
                fontWeight: "bold",
                fontSize: ".9rem",
              };
            },
          }}
          rootStyles={{ paddingBottom: "2rem" }}
        >
          {appContext.user && (
            <div className={classes.userContainer}>
              <div className={classes.userPic}>
                <img
                  src={
                    appContext.user.profilePicture
                      ? appContext.user.profilePicture
                      : "/assets/img/noProfile.png"
                  }
                  alt=""
                />
              </div>
              <div className={classes.userInfo}>
                <h3>{appContext.user.fullName}</h3>
              </div>
            </div>
          )}

          <MenuItem
            active={pathName === "asdasda//asdasd"}
            onClick={() => {
              ScrollTop();
              toggleSidebar();
              appContext.isLoggedIn
                ? appContext.setShowAddCompanyModal(true)
                : appContext.setShowLoginModal(true);
            }}
          >
            <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
              <span className={classes.menuItemContentText}>Memnunsan Yaz</span>
            </div>
          </MenuItem>
          {!appContext.isLoggedIn ? (
            <div className={classes.buttonBox}>
              <button
                className="btnRedBorder"
                onClick={() => {
                  ScrollTop();
                  toggleSidebar();
                  appContext.setShowLoginModal(true);
                }}
              >
                Giriş Yap / Üye Ol
              </button>
            </div>
          ) : (
            <Fragment>
              {MenuItemHandler("/profile", "Profil")}
              <MenuItem
                active={pathName === "asdasda//asdasd"}
                onClick={() => appContext.LogoutHandler()}
              >
                <div
                  className={`menuItemContentBox ${classes.menuItemContentBox}`}
                >
                  <span className={classes.menuItemContentText}> Çıkış</span>
                </div>
              </MenuItem>
            </Fragment>
          )}
        </Menu>
      </Sidebar>
    </Fragment>
  );
};
