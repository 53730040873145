import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import {
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
} from "../../../util/commonUtil";
import {
  SwalErrorMessage,
  SwallApprove,
  SwallDelete,
  SwalSuccess,
} from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { CommonCrudForm } from "../../../components/admin/commonCrudForm/CommonCrudForm";
import { AddComment } from "../../../components/client/addComment/AddComment";
import CommentBox from "../../../components/client/commentBox/CommentBox";
import Swal from "sweetalert2";

export const CommentRejected = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Reddedilmiş Yorum";
  const apiUrl = "comment";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) => {
        return (
          <div className="dataTableProcess">
            <button
              className="dataTableProcessEdit"
              onClick={() => EditHandler(row)}
            >
              İncele
            </button>
            <button
              className="dataTableProcessDelete"
              onClick={() => DeleteHandler(row)}
            >
              Sil
            </button>
          </div>

          // <div className="dataTableProcess">
          //
          //   {DataTableProcess(
          //     row,
          //     (event) => DeleteHandler(event),
          //     () => EditHandler(row),
          //     null,
          //   )}
          // </div>
        );
      },
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "150px",
    },
    {
      name: "Firma",
      selector: (row) => row.companyName,
      filterName: "companyName",
      sortable: true,
      width: "auto",
    },
    {
      name: "Başlık",
      selector: (row) => row.title,
      filterName: "title",
      sortable: true,
      width: "auto",
    },
    {
      name: "Kullanıcı",
      selector: (row) => row.fullName,
      filterName: "fullName",
      sortable: true,
      width: "200px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        `${apiUrl}/RejectedList`,
        "get",
        null,
        null,
        "admin",
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (status) => {
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        let rejectDescription = "";
        if (status === 4) {
          const { value: text } = await Swal.fire({
            input: "textarea",
            inputLabel: "Reddedilme Sebebi",
            inputPlaceholder: "Neden reddettiğinizi yazınız...",
            inputAttributes: {
              "aria-label": "Neden reddettiğinizi yazınız",
            },
            showCancelButton: true,
            confirmButtonColor: "#30a616",
            cancelButtonColor: "#d33",
            confirmButtonText: "Kaydet",
            cancelButtonText: "İptal",
          });

          if (!text) {
            SwalErrorMessage("Neden reddettiğinizi yazmalısınız");
            return;
          }
          rejectDescription = text;
        }

        const response = await appContext.FetchHandler(
          `${apiUrl}/approve`,
          "post",
          "",
          {
            id: formInput.id,
            status: status,
            rejectDescription: rejectDescription,
          },
        );

        if (response.ok) {
          setCrudState(!crudState);
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (data) => {
    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          `${apiUrl}`,
          "delete",
          null,
          { id: data.id },
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} `}
        >
          <CommentBox
            data={[formInput]}
            isAdmin={true}
            approveHandler={SubmitHandler}
            rejectDescription={formInput.rejectDescription}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
