import React, { Fragment } from "react";
import classes from "./FileUpload.module.css";
import { ConvertFileBase64 } from "../../../util/commonUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faRemove } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { SwalErrorMessage } from "../../../util/swalUtil";

export const FileUpload = ({ formInput, setFormInput }) => {
  const RemoveFile = (event) => {
    event.preventDefault();
    const filtered = Array.from(formInput.files).filter(
      (x) => x.fileName !== event.target.id,
    );

    if (filtered.length === 0) document.getElementById("file").value = "";

    setFormInput((prev) => ({
      ...prev,
      files: filtered,
    }));
  };
  const FileHandler = (event) => {
    const file = event.target.files[0];
    if (file.size > 1012838) {
      SwalErrorMessage("En fazla 1mb boyutunda dosya yükleyebilirsiniz");
      return;
    }
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      SwalErrorMessage(
        "Sadece jpg, jpeg ve png formatinda dosya yükleyebilirsiniz.",
      );
      return;
    }
    if (formInput.files.length >= 4) {
      SwalErrorMessage("En fazla 4 adet görsel yükleyebilirsiniz.");
      return;
    }
    ConvertFileBase64(file).then((resolve) => {
      setFormInput((prev) => ({
        ...prev,
        files: [
          ...prev.files,
          {
            base64String: resolve.base64,
            fileName: file.name,
            fileUrl: resolve.preview,
            fileType: file.type,
            file: file,
          },
        ],
      }));
    });
  };

  useEffect(() => {}, [formInput]);

  return (
    <Fragment>
      <div className={classes.fileContainer}>
        <label htmlFor="file" className={classes.fileBox}>
          <FontAwesomeIcon icon={faImage} style={{ fontSize: "4rem" }} />
          <h5>Görsel Seçiniz</h5>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {formInput?.files?.length > 0 &&
              Array.from(formInput.files).map((y, j) => {
                return (
                  <div
                    className={classes.fileListBox}
                    title="Kaldır"
                    key={j}
                    id={y.fileName}
                    onClick={(event) => RemoveFile(event)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        pointerEvents: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={y.fileUrl} alt="" />
                      <p>{y.fileName}</p>
                    </div>

                    <FontAwesomeIcon
                      className={classes.removeIcon}
                      icon={faRemove}
                    />
                  </div>
                );
              })}
          </div>
        </label>

        <input
          id="file"
          name="file"
          type="file"
          className={classes.customFile}
          onChange={(event) => FileHandler(event)}
        />
      </div>
    </Fragment>
  );
};
