import React, { Fragment, useContext, useState } from "react";
import { ConvertFileBase64, EntityStatusData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";
import {
  SwallApprove,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { AppContext } from "../../../context/app-context";
import { Validation } from "../../ui/input/Validation";

export const AddCompany = ({
  setShowAddCompanyModal,
  formInput,
  setFormInput,
  formInputIsValid,
  setFormInputIsValid,
}) => {
  const appContext = useContext(AppContext);
  const apiUrl = "company";

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      const formData = new FormData(document.getElementById("form"));
      if (formInput.id !== undefined) formData.append("id", formInput.id);

      // formInput.category.forEach((x, i) => {
      //   formData.append(`category[${i}][value]`, x.value);
      //   formData.append(`category[${i}][label]`, x.label);
      // });
      if (
        formInput.category !== undefined &&
        formInput.category?.value != null
      ) {
        formData.delete("category");
        formData.append(`category[value]`, formInput?.category?.value);
        formData.append(`category[label]`, formInput?.category?.label);
      }

      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          `${apiUrl}/CreateClient`,
          "post",
          "formData",
          formData,
        );

        if (response.ok) {
          event.target.reset();
          setShowAddCompanyModal(false);
          SwalSuccessMessage(response.data);
        }
      }
    });
  };

  const GetCatData = () => {
    const _tempData = [];
    for (const item of appContext.categoryData) {
      _tempData.push({ value: item.id, label: item.name });
    }

    return _tempData;
  };

  return (
    <Fragment>
      <h2>Marka Ekle</h2>
      <hr />
      <Input
        label="Marka Ad"
        type="input"
        style={{ width: "100%" }}
        validation={formInput?.name != null && formInputIsValid.name}
        input={{
          id: "name",
          type: "text",

          value:
            formInput != null && formInput.name != null ? formInput.name : "",
          onChange: (event) => {
            const value = event.target.value;
            setFormInputIsValid((prev) => ({
              ...prev,
              name: Validation("Marka Ad", value, {
                isRequired: true,
                min: 2,
                max: 300,
              }),
            }));
            setFormInput((prev) => ({
              ...prev,
              name: event.target.value,
            }));
          },
        }}
      />
      <Fragment>
        <div style={{ display: "flex" }}>
          <Input
            label="Logo"
            type="input"
            input={{
              id: "logo",
              type: "file",
              accept: "image/*",
              onChange: async (event) => {
                const baseFile = await ConvertFileBase64(event.target.files[0]);
                setFormInput((prev) => ({
                  ...prev,
                  logo: event.target.files[0],
                  mediaUrl: baseFile.preview,
                }));
              },
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={
              formInput != null && formInput.mediaUrl != null
                ? formInput.mediaUrl
                : ""
            }
            alt=""
            style={{
              width: "100%",
              height: "150px",
              objectFit: "contain",
              margin: "1rem",
              overflow: "none",
            }}
          />
        </div>
      </Fragment>
      <div style={{ display: "flex" }}>
        <Input
          label="Kategori"
          type="selectSearch"
          isMulti={false}
          option={GetCatData()}
          style={{ width: "100%" }}
          validation={formInput?.category != null && formInputIsValid.category}
          value={
            formInput != null &&
            formInput.category != null &&
            formInput.category
          }
          input={{
            id: "category",
            onChange: (event, action) => {
              setFormInputIsValid((prev) => ({
                ...prev,
                category: { status: true, message: "" },
              }));
              setFormInput((prev) => ({
                ...prev,
                category: event,
              }));
            },
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Input
          label="Telefon"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "phoneNumber",
            type: "text",
            value:
              formInput != null && formInput.phoneNumber != null
                ? formInput.phoneNumber
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                phoneNumber: event.target.value,
              }));
            },
          }}
        />
        <Input
          label="Email"
          type="input"
          style={{ width: "100%" }}
          validation={formInput?.email != null && formInputIsValid.email}
          input={{
            id: "email",
            type: "email",
            value:
              formInput != null && formInput.email != null
                ? formInput.email
                : "",
            onChange: (event) => {
              setFormInputIsValid((prev) => ({
                ...prev,
                email: Validation("Email", event.target.value, {
                  type: 2,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            },
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Input
          label="Ülke"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "country",
            type: "text",

            value:
              formInput != null && formInput.country != null
                ? formInput.country
                : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                country: event.target.value,
              }));
            },
          }}
        />
        <Input
          label="Şehir"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "city",
            type: "text",

            value:
              formInput != null && formInput.city != null ? formInput.city : "",
            onChange: (event) => {
              setFormInput((prev) => ({
                ...prev,
                city: event.target.value,
              }));
            },
          }}
        />
      </div>
      <Input
        label="Adres"
        type="textArea"
        style={{ width: "100%" }}
        input={{
          id: "address",
          type: "text",

          value:
            formInput != null && formInput.address != null
              ? formInput.address
              : "",
          onChange: (event) => {
            setFormInput((prev) => ({
              ...prev,
              address: event.target.value,
            }));
          },
        }}
      />
      <Input
        label="Kısa Açıklama"
        type="textArea"
        style={{ width: "100%" }}
        input={{
          id: "shortDescription",
          type: "text",

          value:
            formInput != null && formInput.shortDescription != null
              ? formInput.shortDescription
              : "",
          onChange: (event) => {
            setFormInput((prev) => ({
              ...prev,
              shortDescription: event.target.value,
            }));
          },
        }}
      />
      <hr />
    </Fragment>
  );
};
