import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import classes from "./Header.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBars,
  faChevronDown,
  faIdBadge,
  faIdCard,
  faMagnifyingGlass,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useProSidebar } from "react-pro-sidebar";
import { Input } from "../../../components/ui/input/Input";
import { useLocation } from "react-router";
import { ModalClient } from "../../../components/ui/modal/ModalClient";
import { LoginForm } from "../../../components/client/loginForm/LoginForm";
import { AddCompany } from "../../../components/client/addCompany/AddCompany";
import { AddComment } from "../../../components/client/addComment/AddComment";
import { SwallApprove, SwalSuccess } from "../../../util/swalUtil";
import { ScrollTop } from "../../../util/commonUtil";

export const Header = () => {
  const appContext = useContext(AppContext);
  const { collapseSidebar, toggleSidebar } = useProSidebar();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [searchFormInput, setSearchFormInput] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const CollapsHandler = () => {
    collapseSidebar();
    const contentBoxs = document.querySelectorAll(".menuItemContentBox");
    for (const item of contentBoxs) {
      item.style.justifyContent = "center";
    }
  };

  let activeStyle = {
    // textDecoration: "underline",
    color: "#FF0030",
  };

  const SearchHandler = async () => {
    const response = await appContext.FetchHandler(
      `search`,
      "post",
      null,
      searchFormInput.searchKey,
    );

    if (response.ok) {
      navigate("/search", {
        state: { name: searchFormInput.searchKey, data: response.data },
      });
    }
  };

  return (
    <Fragment>
      <header className={classes.header} id="header">
        <main className={classes.mainMobile}>
          <div className={classes.mainMobileTop}>
            <Link className={classes.mobileLogo} to="/">
              <img src="/assets/img/logo.png" alt="logo" />
            </Link>
            <button
              className="sb-button"
              onClick={() => toggleSidebar()}
              style={{ color: "#FF0000" }}
            >
              <FontAwesomeIcon icon={faBars} style={{ fontSize: "1.5rem" }} />
            </button>
          </div>
          <div className={classes.searchBox}>
            <form
              id="searchForm"
              onSubmit={async (event) => {
                event.preventDefault();
                await SearchHandler();
              }}
            >
              <Input
                type="input"
                style={{ width: "100%" }}
                input={{
                  id: "searchKey",
                  type: "text",
                  className: classes.searchBoxInput,
                  required: "required",
                  placeholder: "Firma, marka, mekan ara",
                  value:
                    searchFormInput != null && searchFormInput.searchKey != null
                      ? searchFormInput.searchKey
                      : "",
                  onChange: (event) => {
                    setSearchFormInput((prev) => ({
                      ...prev,
                      searchKey: event.target.value,
                    }));
                  },
                }}
              />
              <button type="submit" className={classes.searchBoxIcon}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </form>
          </div>
        </main>

        <main className={classes.mainDesk}>
          <div className={classes.headerTop}>
            <div className={classes.logoBox}>
              <Link to="/">
                <img src="/assets/img/logo.png" alt="logo" />
              </Link>
            </div>
            <div className={classes.searchBox}>
              <form
                id="searchForm"
                onSubmit={async (event) => {
                  event.preventDefault();
                  await SearchHandler();
                }}
              >
                <Input
                  type="input"
                  style={{ width: "100%" }}
                  input={{
                    id: "searchKey",
                    type: "text",
                    className: classes.searchBoxInput,
                    required: "required",
                    placeholder: "Firma, marka, mekan ara",
                    value:
                      searchFormInput != null &&
                      searchFormInput.searchKey != null
                        ? searchFormInput.searchKey
                        : "",
                    onChange: (event) => {
                      setSearchFormInput((prev) => ({
                        ...prev,
                        searchKey: event.target.value,
                      }));
                    },
                  }}
                />
                <button type="submit" className={classes.searchBoxIcon}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </form>
            </div>
            <div className={classes.userContainer}>
              {!appContext.isLoggedIn ? (
                <Fragment>
                  <button
                    onClick={() => {
                      ScrollTop();
                      appContext.setShowLoginModal(true);
                    }}
                  >
                    Giriş Yap / Üye Ol
                  </button>
                </Fragment>
              ) : (
                <div className={classes.userBox}>
                  <button onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
                    <img
                      src={
                        appContext.user &&
                        appContext.user.profilePicture != null
                          ? appContext.user.profilePicture
                          : "/assets/img/noProfile.png"
                      }
                      alt=""
                    />
                    <h4>{appContext.user && appContext.user.fullName}</h4>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ color: "#44444", marginLeft: ".5rem" }}
                    />
                  </button>

                  {isUserMenuOpen && (
                    <Fragment>
                      <div
                        className={classes.userMenuBd}
                        onClick={() => setIsUserMenuOpen(false)}
                      ></div>
                      <div className={classes.userLinkBox}>
                        <Link
                          to="/profile"
                          onClick={() => setIsUserMenuOpen(false)}
                        >
                          <FontAwesomeIcon icon={faIdCard} />
                          <span>Profil</span>
                        </Link>

                        <Link onClick={() => appContext.LogoutHandler()}>
                          <FontAwesomeIcon icon={faRightFromBracket} />
                          <span>Çıkış</span>
                        </Link>
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
              <div className={classes.addCompanyBtn}>
                <button
                  onClick={() => {
                    ScrollTop();
                    appContext.isLoggedIn
                      ? appContext.setShowAddCompanyModal(true)
                      : appContext.setShowLoginModal(true);
                  }}
                >
                  Memnunsan Yaz
                </button>
              </div>
            </div>
          </div>

          {/*<div className={classes.headerBottom}>*/}
          {/*  <nav>*/}
          {/*    <ul>*/}
          {/*      {appContext.categoryData.map((x, i) => (*/}
          {/*        <li key={x.id}>*/}
          {/*          <NavLink*/}
          {/*            to={`/companyList?name=${x.name}`}*/}
          {/*            state={x}*/}
          {/*            style={*/}
          {/*              location.pathname + location.search ===*/}
          {/*              `/companyList?name=${x.name}`*/}
          {/*                ? activeStyle*/}
          {/*                : undefined*/}
          {/*            }*/}
          {/*          >*/}
          {/*            {x.name}*/}
          {/*          </NavLink>*/}
          {/*        </li>*/}
          {/*      ))}*/}
          {/*    </ul>*/}
          {/*  </nav>*/}
          {/*</div>*/}
        </main>
      </header>

      {appContext.showLoginModal && (
        <ModalClient
          showModal={appContext.showLoginModal}
          setShowModal={appContext.setShowLoginModal}
          style={{ padding: 0 }}
        >
          <LoginForm />
        </ModalClient>
      )}

      {appContext.showAddCompanyModal && (
        <ModalClient
          showModal={appContext.showAddCompanyModal}
          setShowModal={appContext.setShowAddCompanyModal}
        >
          <AddComment
            companyId={null}
            setShowModal={appContext.setShowAddCompanyModal}
          />
          {/*<AddCompany*/}
          {/*  setShowAddCompanyModal={appContext.setShowAddCompanyModal}*/}
          {/*/>*/}
        </ModalClient>
      )}
    </Fragment>
  );
};
