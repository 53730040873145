import React, { Fragment, useEffect } from "react";
import classes from "./ModalAdmin.module.css";

export const ModalAdmin = (props) => {
  return (
    <Fragment>
      {props.showModal && (
        <Fragment>
          <div
            className={classes.modalBackdrop}
            style={{ backgroundColor: props.isLogin && "#043657" }}
            onClick={() => !props.isLogin && props.setShowModal(false)}
          ></div>
          <div
            className={classes.modal}
            style={props.style && props.style}
            id="modalAdmin"
          >
            <div
              style={{
                display: "flex",
                justifyContent: props.title ? "space-between" : "flex-end",
              }}
            >
              {props.title && <h2>{props.title}</h2>}

              <button
                type="button"
                className={classes.modalClose}
                onClick={() => !props.isLogin && props.setShowModal(false)}
              >
                X
              </button>
            </div>
            <hr></hr>
            {props.children}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
