import React from "react";
import classes from "./BlogWidget.module.css";
import { Link } from "react-router-dom";
import Moment from "moment";

const BlogWidget = ({ data }) => {
  return (
    <div className={classes.main}>
      <div className={classes.mainTop}>
        <h2>Blog</h2>
      </div>
      <div className={classes.mainContent}>
        {data.length > 0 &&
          data.map((x, i) => (
            <Link
              className={classes.item}
              key={x.id}
              to={`/blog/${x.name.replaceAll("?","")}/${x.id}`}
              state={x}
            >
              <img src={x.mediaUrl} title={x.name} alt={x.name} />
              <h3>{x.name}</h3>
              <p>{x.shortDescription}</p>
              <p>{Moment(x.updatedDate).format("DD/MM/YYYY")}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default BlogWidget;
