import React, { Fragment, useEffect } from "react";
import classes from "./ModalClient.module.css";

export const ModalClient = (props) => {
  return (
    <Fragment>
      {props.showModal && (
        <Fragment>
          <div
            className={classes.modalBackdrop}
            onClick={() => props.setShowModal(false)}
          ></div>
          <div
            className={classes.modal}
            style={props.style && props.style}
            id="modalAdmin"
          >
            <div>
              {props.title && <h2>{props.title}</h2>}

              <button
                type="button"
                className={classes.modalClose}
                onClick={() => props.setShowModal(false)}
              >
                X
              </button>
            </div>
            {props.children}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
