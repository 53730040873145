import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./CommentBox.module.css";
import ReviewStar from "../../ui/reviewStar/ReviewStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  SwallApprove,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { AppContext } from "../../../context/app-context";
import { EntityStatusHtml, ScrollTop } from "../../../util/commonUtil";
import { ModalClient } from "../../ui/modal/ModalClient";
import { Input } from "../../ui/input/Input";

const CommentBox = ({
  data,
  widget,
  isAdmin,
  approveHandler,
  rejectDescription,
  profile,
  isCompanyUser,
}) => {
  const appContext = useContext(AppContext);
  const [commentData, setCommentData] = useState(data);
  useEffect(() => {
    setCommentData(data);
  }, [data]);

  const [companyAnswerForm, setCompanyAnswerForm] = useState({
    fullName: null,
    commentId: null,
    answer: null,
  });
  const [showModal, setShowModal] = useState(false);

  const RateHandler = async (commentId, rate) => {
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          `comment/rate`,
          "post",
          null,
          { commentId: commentId, commentRate: rate },
        );
        if (response.ok) {
          let _tempData = commentData;
          const comment = _tempData.filter((x) => x.id === commentId)[0];
          if (response.statusCode === 202) {
            if (rate === 0) {
              comment.like += 1;
              comment.disLike -= 1;
            } else {
              comment.disLike += 1;
              comment.like -= 1;
            }
          } else {
            rate === 0 ? (comment.like += 1) : (comment.disLike += 1);
          }

          setCommentData(_tempData);
          SwalSuccessMessage(response.data);
        }
      }
    });
  };

  const CompanyAnswerHandler = async () => {
    const response = await appContext.FetchHandler(
      "comment/companyAnswer",
      "post",
      "",
      companyAnswerForm,
    );
    if (response.ok) {
      setShowModal(false);
      SwalSuccess();
      window.location.reload();
    }
  };

  return (
    <div className={widget ? classes.containerWidget : classes.containerColumn}>
      {showModal && (
        <ModalClient
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${companyAnswerForm.fullName} kullanıcısına cevabınız`}
        >
          <form
            id="form"
            className="form"
            onSubmit={async (event) => {
              event.preventDefault();
              await CompanyAnswerHandler();
            }}
          >
            <div className="row">
              <Input
                label="Cevabınız"
                type="textArea"
                style={{ width: "100%" }}
                input={{
                  id: "answer",
                  type: "text",
                  value:
                    companyAnswerForm.answer != null
                      ? companyAnswerForm.answer
                      : "",
                  onChange: (event) => {
                    setCompanyAnswerForm((prev) => ({
                      ...prev,
                      answer: event.target.value,
                    }));
                  },
                }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button type="submit" className="saveBtn">
                Kaydet
              </button>
              <button
                type="button"
                className="cancelBtn"
                onClick={() => setShowModal(false)}
              >
                İptal
              </button>
            </div>
          </form>
        </ModalClient>
      )}
      {commentData &&
        commentData.length > 0 &&
        commentData.map((x, i) => (
          <Fragment key={i}>
            <div className={widget ? classes.itemWidget : classes.item} key={i}>
              <div className={classes.top}>
                <div className={classes.profile}>
                  <img src="/assets/img/noProfile.png" alt="" />
                  <h3>{x.fullName}</h3>
                </div>
                <div className={classes.reviewStar}>
                  {/*<ReviewStar rate={x.rate} />*/}
                  <p>{moment(x.updatedDate).format("DD/MM/YYYY")}</p>
                </div>
              </div>
              {(widget || isAdmin || profile) && (
                <Fragment>
                  {x.isUserComment && (
                    <div style={{ margin: "1rem 0" }}>
                      {EntityStatusHtml(x.entityStatus)}
                    </div>
                  )}

                  <Link
                    to={`/company/${x.companyName}/${x.companyId}`}
                    className={classes.companyName}
                  >
                    {x.companyName}
                  </Link>
                </Fragment>
              )}
              <div className={classes.middle}>
                <h3>{x.title}</h3>
                <p className={widget ? classes.widgetP : ""}>{x.comment}</p>

                {!isAdmin ? (
                  <div
                    className={classes.likeContainer}
                    style={{ margin: widget ? "0" : "1rem 0" }}
                  >
                    <div
                      className={classes.likeBox}
                      onClick={async () => {
                        await RateHandler(x.id, 0);
                      }}
                    >
                      <FontAwesomeIcon icon={faThumbsUp} />
                      <span style={{ marginLeft: ".5rem" }}>{x.like}</span>
                    </div>
                    <div
                      className={classes.unLikeBox}
                      onClick={async () => {
                        await RateHandler(x.id, 1);
                      }}
                    >
                      <FontAwesomeIcon icon={faThumbsDown} />
                      <span style={{ marginLeft: ".5rem" }}>{x.disLike}</span>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {x.entityStatus === 4 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          backgroundColor: "darkorange",
                          padding: "2rem",
                          borderRadius: ".7rem",
                          margin: "1rem 0",
                        }}
                      >
                        <label style={{ fontWeight: "bold" }}>
                          Reddedilme Sebebi
                        </label>
                        <p>{rejectDescription && rejectDescription}</p>
                      </div>
                    )}

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        type="submit"
                        className="btnSave"
                        onClick={() => approveHandler(0)}
                      >
                        Onayla
                      </button>
                      <button
                        type="button"
                        className="btnCancel"
                        onClick={() => approveHandler(4)}
                      >
                        Reddet
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {!widget && (
                <div className={classes.bottom}>
                  {x.youtubeUrl != null && (
                    // <div className={classes.youtube}>
                    //   <iframe
                    //     width="100%"
                    //     height="315"
                    //     src={x.youtubeUrl}
                    //     title="YouTube video player"
                    //     frameBorder="0"
                    //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    //     referrerPolicy="strict-origin-when-cross-origin"
                    //     allowFullScreen
                    //   ></iframe>
                    // </div>
                    <Link target="_blank" to={x.youtubeUrl}>
                      {x.youtubeUrl}{" "}
                    </Link>
                  )}
                  {x.mediaUrl != null && x.mediaUrl.length > 0 && (
                    <Fragment>
                      <div
                        className={classes.media}
                        style={{ flex: widget ? "unset" : 1 }}
                      >
                        <PhotoProvider>
                          {x.mediaUrl.map((y, j) => {
                            return (
                              <PhotoView src={y} key={j}>
                                <img
                                  src={y}
                                  alt=""
                                  className={classes.mediaItem}
                                />
                              </PhotoView>
                            );
                          })}
                        </PhotoProvider>
                      </div>
                    </Fragment>
                  )}
                </div>
              )}

              {isCompanyUser && (
                <div>
                  <button
                    className="btnOrange"
                    onClick={() => {
                      ScrollTop();
                      setCompanyAnswerForm((prev) => ({
                        ...prev,
                        fullName: x.fullName,
                        commentId: x.id,
                        answer: x.companyAnswer,
                      }));
                      setShowModal(true);
                    }}
                  >
                    Marka Cevabı
                  </button>
                </div>
              )}
            </div>
            {!widget && x.companyAnswer && (
              <div className={`${classes.item} ${classes.companyAnswer}`}>
                <h3>Marka Cevabı</h3>
                <p>{x.companyAnswer}</p>
              </div>
            )}
          </Fragment>
        ))}
    </div>
  );
};

export default CommentBox;
