import React, { useContext, useEffect, useState } from "react";
import classes from "./companyDetail.module.css";
import { useLocation, useParams } from "react-router";
import { AppContext } from "../../../context/app-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReviewStar from "../../../components/ui/reviewStar/ReviewStar";
import HtmlReactParser from "html-react-parser";
import CommentBox from "../../../components/client/commentBox/CommentBox";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { ModalClient } from "../../../components/ui/modal/ModalClient";
import GiveStar from "../../../components/ui/giveStar/GiveStar";
import { AddComment } from "../../../components/client/addComment/AddComment";
import { ScrollTop } from "../../../util/commonUtil";

const CompanyDetail = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const { name, id } = useParams();
  const [companyData, setCompanyData] = useState(state);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [crudState, setCrudState] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [isCompanyUser, setIsCompanyUser] = useState(false);

  useEffect(() => {
    ScrollTop();
    GetCompany();
  }, [location]);
  

  useEffect(() => {
    if (appContext.isLoggedIn) {
      if (companyData?.companyUserId != null) {
        if (appContext.user.userId != null) {
          if (appContext.user.userId == companyData.companyUserId) {
            setIsCompanyUser(true);
          }
        }
      }
    }
  }, [appContext.user, companyData]);

  const GetCompany = async () => {
    const response = await appContext.FetchHandler(
      "Company/Detail",
      "post",
      null,
      { id: id },
    );

    if (response.ok) {
      setCompanyData(response.data);
    }
  };

  useEffect(() => {
    GetComments();
  }, [crudState]);

  const GetComments = async () => {
    const response = await appContext.FetchHandler(
      "comment/companyComments",
      "post",
      null,
      { id: id },
    );

    if (response.ok) {
      setCommentData(response.data);
      const userComment = Array.from(response.data).filter(
        (x) => x.isUserComment,
      )[0];
      if (userComment !== undefined) {
      }
    }
  };

  return (
    <div className="pageContainerColumn">
      {showCommentModal && (
        <ModalClient
          showModal={showCommentModal}
          setShowModal={setShowCommentModal}
        >
          <AddComment
            setShowModal={setShowCommentModal}
            companyId={companyData.id}
            setCrudState={setCrudState}
          />
        </ModalClient>
      )}
      {companyData && (
        <div className={classes.companyContainer}>
          <div className={classes.companyTop}>
            <div className={classes.companyTopLeft}>
              <div className={classes.companyTopLeftTop}>
                <img src={companyData.mediaUrl} alt={companyData.name} />
                <h1>{companyData.name}</h1>
              </div>
              <div className={classes.companyTopLeftBottom}>
                {/*<ReviewStar rate={companyData.rate} />*/}
                <p>
                  Toplam Yorum:
                  <span style={{ fontWeight: "bold" }}>
                    {companyData.totalComment}
                  </span>
                </p>
              </div>
            </div>
            <div className={classes.companyTopMiddle}>
              {companyData.phoneNumber && (
                <div className={classes.contactItem}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={classes.contactItemIcon}
                  />
                  <Link
                    to={`tel:${companyData.phoneNumber?.replaceAll(" ", "")}`}
                  >
                    {companyData.phoneNumber}
                  </Link>
                </div>
              )}

              {companyData.email && (
                <div className={classes.contactItem}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={classes.contactItemIcon}
                  />

                  <Link to={`mailto:${companyData.email?.replaceAll(" ", "")}`}>
                    {companyData.email}
                  </Link>
                </div>
              )}
              {companyData.address && (
                <div className={classes.contactItem}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className={classes.contactItemIcon}
                  />
                  <div className={classes.address}>
                    {companyData.address}
                    <br />
                    {companyData.city && companyData.city}{" "}
                    {companyData.country && companyData.country}
                  </div>
                </div>
              )}
            </div>
            <div className={classes.companyTopRight}>
              <button
                className={classes.memnunsanYazBtn}
                onClick={() => {
                  ScrollTop();
                  appContext.isLoggedIn
                    ? setShowCommentModal(true)
                    : appContext.setShowLoginModal(true);
                }}
              >
                Memnunsan Yaz
              </button>
            </div>
          </div>
          <div className={classes.companyBottom}>
            <p>{companyData.shortDescription}</p>
            {companyData.longDescription &&
              HtmlReactParser(companyData.longDescription)}
          </div>
        </div>
      )}
      <div className={classes.commentContainer}>
        <CommentBox data={commentData} isCompanyUser={isCompanyUser} />
      </div>
    </div>
  );
};

export default CompanyDetail;
