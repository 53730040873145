import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import {
  ConfirmedBooleanHtml,
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
  UserTypeHtml,
} from "../../../util/commonUtil";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { CommonCrudForm } from "../../../components/admin/commonCrudForm/CommonCrudForm";
import { UserEditForm } from "../../../components/admin/userEditForm/UserEditForm";

export const UserList = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Üyeler";
  const apiUrl = "user";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [dataList, setDataList] = useState();
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) => {
        return (
          <div className="dataTableProcess">
            <button
              className="dataTableProcessEdit"
              onClick={() => EditHandler(row)}
            >
              Düzenle
            </button>
          </div>
        );
      },
    },
    {
      name: "Durum",
      selector: (row) => ConfirmedBooleanHtml(row.emailConfirmed),
      sortable: true,
      width: "120px",
    },
    {
      name: "Tip",
      selector: (row) => UserTypeHtml(row.userType),
      sortable: true,
      width: "120px",
    },
    {
      name: "Ad",
      selector: (row) => row.fullName,
      filterName: "fullName",
      sortable: true,
      width: "auto",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      filterName: "email",
      sortable: true,
      width: "200px",
    },
    {
      name: "Telefon",
      selector: (row) => row.phoneNumber,
      filterName: "phoneNumber",
      sortable: true,
      width: "200px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        `${apiUrl}/List`,
        "get",
        null,
        null,
        "admin",
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        formInput.emailConfirmed =
          formInput.emailConfirmed == "true" ? true : false;
        const response = await appContext.FetchHandler(
          `${apiUrl}/UserUpdate`,
          "put",
          null,
          formInput,
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${formInput.fullName} -  Düzenle `}
        >
          <UserEditForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
            isMedia={true}
            hideShort={false}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
