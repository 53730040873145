import React, { Fragment, useContext, useState } from "react";
import { Input } from "../../ui/input/Input";
import {
  SwalErrorMessage,
  SwallApprove,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { AppContext } from "../../../context/app-context";
import classes from "./LoginForm.module.css";
import { CheckFormValid, Validation } from "../../ui/input/Validation";
import Swal from "sweetalert2";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export const LoginForm = ({ setShowModal }) => {
  const appContext = useContext(AppContext);
  const apiUrl = "auth";
  const [formInput, setFormInput] = useState({
    fullName: null,
    password: null,
    birthDate: null,
    phoneNumber: null,
    email: null,
    kullaniciSozlesme: null,
    otp: null,
    gToken: null,
  });
  const [formType, setFormType] = useState(0);
  const [title, setTitle] = useState("Giriş Yap");
  const [loginFormIsValid, setLoginFormIsValid] = useState({
    email: {
      status: false,
      message: "Email: Zorunludur.",
    },
    password: {
      status: false,
      message: "Şifre: Zorunludur.",
    },
  });

  const [forgotPasswordFormIsValid, setForgotPasswordFormIsValid] = useState({
    email: {
      status: false,
      message: "Email: Zorunludur.",
    },
  });

  const [registerFormIsValid, setRegisterFormIsValid] = useState({
    fullName: { status: false, message: "Ad Soyad: Zorunludur." },
    email: { status: false, message: "Email: Zorunludur." },
    phoneNumber: { status: false, message: "Telefon Numarası: Zorunludur." },
    password: { status: false, message: "Şifre: Zorunludur." },
    // birthDate: { status: false, message: "Doğum Tarihi: Zorunludur." },
    kullaniciSozlesme: { status: false, message: "Kullanıcı Sözleşmesi: Onayı zorunludur." },
    gizlilikSozlesme: { status: false, message: "Gizlilik Sözleşmesi: Onayı zorunludur." },
  });

  const [verifyFormIsValid, setVerifyFormIsValid] = useState({
    otp: { status: false, message: "OTP: zorunludur." },
  });

  const SubmitHandler = async () => {
    let isValid = { status: false, message: "Hata" };
    let submitUrl = "login";
    switch (formType) {
      case 0:
        isValid = CheckFormValid(loginFormIsValid);
        break;
      case 1:
        isValid = CheckFormValid(forgotPasswordFormIsValid);
        submitUrl = "forgotPassword";
        break;
      case 2:
        isValid = CheckFormValid(registerFormIsValid);
        submitUrl = "register";
        break;
      case 3:
        isValid = CheckFormValid(verifyFormIsValid);
        submitUrl = "verifyEmail";
        break;
    }
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    if (formType === 0) {
      const loginRes = await appContext.LoginHandler(formInput, false);
      console.log("loginRes", loginRes);
      if (loginRes.statusCode === 406) {
        setFormType(3);
        setTitle("Doğrula");
      }
    } else {
      const response = await appContext.FetchHandler(
        `${apiUrl}/${submitUrl}`,
        "post",
        null,
        formInput,
      );

      if (response.ok) {
        SwalSuccessMessage(response.data);
        if (formType === 2) {
          setFormType(3);
          setTitle("Doğrula");
        } else if (formType === 3) {
          setTitle("Giriş Yap");
          setFormType(0);
          setLoginFormIsValid({
            email: { status: true, message: "" },
            password: { status: true, message: "" },
          });
        } else {
          setShowModal(false);
        }
      }
    }
  };

  const ResendEmailOtp = async () => {
    await SwallApprove().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          `${apiUrl}/ResendOtpEmail`,
          "post",
          null,
          { email: formInput.email },
        );

        if (response.ok) {
          SwalSuccessMessage(response.data);
        }
      }
    });
  };

  const ShowPassword = () => {
    const password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginLeft}>
        <h1>{title}</h1>
        <form
          id="form"
          onSubmit={async (event) => {
            event.preventDefault();
            await SubmitHandler();
          }}
        >
          {formType === 0 ? (
            <Fragment>
              <Input
                label="Email"
                type="input"
                className={classes.input}
                validation={formInput?.email != null && loginFormIsValid.email}
                input={{
                  id: "Email",
                  type: "text",
                  placeholder: "Email",
                  value:
                    formInput != null && formInput.email != null
                      ? formInput.email
                      : "",
                  onChange: (event) => {
                    setLoginFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setForgotPasswordFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }));
                  },
                }}
              />
              <div className={classes.passwordBox}>
                <Input
                  label="Şifre"
                  type="input"
                  className={classes.input}
                  validation={
                    formInput?.password != null && loginFormIsValid.password
                  }
                  input={{
                    id: "password",
                    type: "password",
                    placeholder: "Şifre",
                    value:
                      formInput != null && formInput.password != null
                        ? formInput.password
                        : "",
                    onChange: (event) => {
                      const value = event.target.value;
                      setLoginFormIsValid((prev) => ({
                        ...prev,
                        password: Validation("Şifre", value, {
                          type: 4,
                          isRequired: true,
                        }),
                      }));
                      setRegisterFormIsValid((prev) => ({
                        ...prev,
                        password: Validation("Şifre", value, {
                          type: 4,
                          isRequired: true,
                        }),
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        password: event.target.value,
                      }));
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    className={classes.passwordWithEyeIcon}
                    title="Şifre göster"
                    onClick={() => ShowPassword()}
                  />
                </Input>

                <button
                  type="button"
                  onClick={() => {
                    setFormType(1);
                    setTitle("Şifremi Unuttum");
                  }}
                >
                  Şifremi Unuttum?
                </button>
              </div>
            </Fragment>
          ) : formType === 1 ? (
            <Fragment>
              <p>Şifreniz Email adresinize gönderilecektir.</p>
              <Input
                label="Email"
                type="input"
                className={classes.input}
                validation={
                  formInput?.email != null && forgotPasswordFormIsValid.email
                }
                input={{
                  id: "email",
                  type: "email",
                  placeholder: "Email",
                  value:
                    formInput != null && formInput.email != null
                      ? formInput.email
                      : "",
                  onChange: (event) => {
                    setForgotPasswordFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }));
                  },
                }}
              />
            </Fragment>
          ) : formType === 2 ? (
              <Fragment>
                <Input
                    label="Ad Soyad"
                    type="input"
                    className={classes.input}
                    validation={
                        formInput?.fullName != null && registerFormIsValid.fullName
                    }
                    input={{
                      id: "fullName",
                      type: "text",

                      placeholder: "Ad Soyad",
                      value:
                          formInput != null && formInput.fullName != null
                              ? formInput.fullName
                              : "",
                      onChange: (event) => {
                        setRegisterFormIsValid((prev) => ({
                          ...prev,
                          fullName: Validation("Ad Soyad", event.target.value, {
                            isRequired: true,
                            min: 2,
                            max: 30,
                          }),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          fullName: event.target.value,
                        }));
                      },
                    }}
                />
                <Input
                    label="Email"
                    type="input"
                    className={classes.input}
                    validation={
                        formInput?.email != null && registerFormIsValid.email
                    }
                    input={{
                      id: "email",
                      type: "email",
                      placeholder: "Email",
                      value:
                          formInput != null && formInput.email != null
                              ? formInput.email
                              : "",
                      onChange: (event) => {
                        setRegisterFormIsValid((prev) => ({
                          ...prev,
                          email: Validation("Email", event.target.value, {
                            isRequired: true,
                            type: 2,
                          }),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          email: event.target.value,
                        }));
                      },
                    }}
                />
                {/*<Input*/}
                {/*  label="Doğum Tarihi"*/}
                {/*  type="input"*/}
                {/*  className={classes.input}*/}
                {/*  validation={*/}
                {/*    formInput?.birthDate != null && registerFormIsValid.birthDate*/}
                {/*  }*/}
                {/*  input={{*/}
                {/*    id: "birthDate",*/}
                {/*    type: "date",*/}
                {/*    placeholder: "Doğum Tarihi",*/}
                {/*    onChange: (event) => {*/}
                {/*      setRegisterFormIsValid((prev) => ({*/}
                {/*        ...prev,*/}
                {/*        birthDate: Validation(*/}
                {/*          "Doğum Tarihi",*/}
                {/*          event.target.value,*/}
                {/*          {*/}
                {/*            isRequired: true,*/}
                {/*            type: 3,*/}
                {/*          },*/}
                {/*        ),*/}
                {/*      }));*/}
                {/*      setFormInput((prev) => ({*/}
                {/*        ...prev,*/}
                {/*        birthDate: event.target.value,*/}
                {/*      }));*/}
                {/*    },*/}
                {/*  }}*/}
                {/*/>*/}
                <Input
                    label="Telefon Numarası"
                    type="phoneNumber"
                    className={classes.input}
                    onlyCountries={["tr"]}
                    validation={
                        formInput.phoneNumber != null &&
                        registerFormIsValid.phoneNumber
                    }
                    input={{
                      id: "phoneNumber",
                      type: "text",
                      onChange: (event) => {
                        setRegisterFormIsValid((prev) => ({
                          ...prev,
                          phoneNumber: Validation("Telefon Numarası", event, {
                            isRequired: true,
                            min: 12,
                          }),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          phoneNumber: event,
                        }));
                      },
                    }}
                />
                <Input
                    type="input"
                    style={{width: "100%"}}
                    validation={
                        formInput.password != null && registerFormIsValid.password
                    }
                    label="Şifre"
                    input={{
                      id: "password",
                      type: "password",
                      placeholder: "Şifre",
                      onChange: (event) => {
                        const value = event.target.value;
                        setRegisterFormIsValid((prev) => ({
                          ...prev,
                          password: Validation("Şifre", value, {
                            type: 4,
                            isRequired: true,
                          }),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          password: value,
                        }));
                      },
                    }}
                />
                <div className="row">
                  <Input
                      disableLabel={true}
                      label={HTMLReactParser(`<span><span style="color:#FF0000;font-weight:bold">Kullanıcı Sözleşmesi</span>
              okudum onaylıyorum.</span>`)}
                      style={{width: "100%", padding: 0}}
                      validation={
                          formInput.kullaniciSozlesme != null && registerFormIsValid.kullaniciSozlesme
                      }
                      type="input"
                      checked={formInput.kullaniciSozlesme != null && formInput.kullaniciSozlesme}
                      checkboxStyle={{
                        justifyContent: "flex-start",
                        border: "none",
                        height: "1.2rem",
                      }}
                      input={{
                        id: "kullaniciSozlesme",
                        type: "checkbox",

                        onChange: (event) => {
                          Swal.fire({
                            width: 1000,
                            html: "<h1>KULLANICI SÖZLEŞMESİ</h1>\n" +
                                "\n" +
                                "<p>\n" +
                                "  Sevgili ziyaretçimiz, lütfen memnunsan.com web sitemizi ziyaret etmeden önce\n" +
                                "  işbu kullanım koşulları sözleşmesini dikkatlice okuyunuz. Siteye erişiminiz\n" +
                                "  tamamen bu sözleşmeyi kabulünüze ve bu sözleşme ile belirlenen şartlara\n" +
                                "  uymanıza bağlıdır. Şayet bu sözleşmede yazan herhangi bir koşulu kabul\n" +
                                "  etmiyorsanız, lütfen siteye erişiminizi sonlandırınız. Siteye erişiminizi\n" +
                                "  sürdürdüğünüz takdirde, koşulsuz ve kısıtlamasız olarak, işbu sözleşme\n" +
                                "  metninin tamamını kabul ettiğinizin varsayılacağını lütfen unutmayınız.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    memnunsan.com web sitesi memnunsan.com tarafından yönetilmekte olup, bundan sonra SİTE olarak anılacaktır. İşbu siteye\n" +
                                "    ilişkin Kullanım Koşulları, yayınlanmakla yürürlüğe girer. Değişiklik yapma hakkı, tek taraflı olarak SİTE' ye aittir ve\n" +
                                "    SİTE üzerinden güncel olarak paylaşılacak olan bu değişiklikleri tüm kullanıcılarımız baştan kabul etmiş sayılır.\n" +
                                "</p>\n" +
                                "\n" +
                                "<h2>\n" +
                                "    Gizlilik\n" +
                                "</h2>\n" +
                                "<p>\n" +
                                "    Gizlilik, ayrı bir sayfada, kişisel verilerinizin tarafımızca işlenmesinin esaslarını düzenlemek üzere mevcuttur. SİTE'\n" +
                                "    yi kullandığınız takdirde, bu verilerin işlenmesinin gizlilik politikasına uygun olarak gerçekleştiğini kabul edersiniz.\n" +
                                "</p>\n" +
                                "\n" +
                                "<h2>Hizmet Kapsamı</h2>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Memnunsan.com, kişilerin aldıkları mal veya hizmetten memnuniyetlerini ifade edebilecekleri bir platformdur. \n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Siteye üye olanlar, üyelikle ilgili bilgileri tam, eksiksiz ve doğru olarak belirteceklerini kabul ve taahhüt ederler.\n" +
                                "    Yanlış veya eksik bilgi verenlerin üyelikleri SİTE tarafından hiçbir bildirime gerek olmadan sonlandırılabileceği gibi,\n" +
                                "    yanlış ve eksik bilgilendirme sonucu üçüncü kişilerin uğrayacakları tüm zararlardan üye kendisinin sorumlu olacağını\n" +
                                "    kabul ve taahhüt eder.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Üyelik bilgilerinin korunması ve saklanması üyenin kendi sorumluluğundadır. Üye, üyelikle ilgili bilgilerin üçüncü\n" +
                                "    kişilerin eline geçtiğini fark ederse SİTE' ye bildirmesi durumunda üyeliği sonlandırılır.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Üyeye ait SİTE' de bulunan kişisel bilgiler tamamıyla gizli tutulmaktadır. Ancak, SİTE her türlü güvenliği almasına\n" +
                                "    karşın SİTE' ye yapılan siber saldırılar nedeniyle üye bilgileri üçüncü kişilerin eline geçerse SİTE' nin bir\n" +
                                "    sorumluluğu olmayacağını üye kabul ve taahhüt eder.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Üyenin SİTE' de yapacağı yorumlar, SİTE' nin kendi görüşleri değildir. \n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Kullanıcılar, siteyi kullanarak; yasal olarak suç içermeyecek her türlü yorumlarını ifade edebilirler. \n" +
                                "</p>",
                            icon: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#118c11",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Evet",
                            cancelButtonText: "Hayır",
                          }).then(async (result) => {
                            const element = document.getElementById(
                                event.target.id,
                            );
                            if (result.isConfirmed) {
                              element.checked = true;
                              setRegisterFormIsValid((prev) => ({
                                ...prev,
                                kullaniciSozlesme: {status: true, message: ""},
                              }));
                              setFormInput((prev) => ({
                                ...prev,
                                kullaniciSozlesme: true,
                              }));
                            } else {
                              element.checked = false;
                              setFormInput((prev) => ({
                                ...prev,
                                kullaniciSozlesme: false,
                              }));
                            }
                          });
                        },
                      }}
                  />
                </div>
                <div className="row">
                  <Input
                      disableLabel={true}
                      label={HTMLReactParser(`<span><span style="color:#FF0000;font-weight:bold">Gizlilik Sözleşmesi</span>
              okudum onaylıyorum.</span>`)}
                      style={{width: "100%", padding: 0}}
                      validation={
                          formInput.gizlilikSozlesme != null && registerFormIsValid.gizlilikSozlesme
                      }
                      type="input"
                      checked={formInput.gizlilikSozlesme != null && formInput.gizlilikSozlesme}
                      checkboxStyle={{
                        justifyContent: "flex-start",
                        border: "none",
                        height: "1.2rem",
                      }}
                      input={{
                        id: "gizlilikSozlesme",
                        type: "checkbox",

                        onChange: (event) => {
                          Swal.fire({
                            width: 1000,
                            html: "<h1>\n" +
                                "    GİZLİLİK SÖZLEŞMESİ ve KVKK AYDINLATMA METNİ\n" +
                                "</h1>\n" +
                                "\n" +
                                "<p>Bizimle paylaşacağınız kişisel verileriniz özenle korunmaktadır.</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    SİTE; kaliteli ürün ve hizmet sunan gerçek ya da tüzel kişilerin toplum tarafından da tanınmasını, öne çıkmasını\n" +
                                "    kolaylaştırmayı amaçlamaktadır. Bu ortak amaçla SİTE' mizi kullanan üye ya da ziyaretçilerin kişisel verilerinin\n" +
                                "    gizliliği ve güvenliği öncelikli sorumluluğumuzdur.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Biz, memnunsan.com, veri sorumlusu olarak, bu gizlilik ve kişisel verilerin korunması politikası ile, hangi kişisel\n" +
                                "    verilerinizin hangi amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve\n" +
                                "    hukuki sebeplerimiz ile; işlenen verilerinize ilişkin haklarınızın neler olduğu hususunda sizleri aydınlatmayı\n" +
                                "    amaçlıyoruz.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    Toplanan Kişisel Verileriniz, Toplanma Yöntemi, İşlenme Amaçları ve Hukuki Sebebi\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    IP adresiniz ve kullanıcı aracısı bilgileriniz, sadece analiz yapmak amacıyla ve çerezler (cookies) vb. teknolojiler\n" +
                                "    vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve bazen de analitik sağlayıcılar, reklam ağları, arama bilgi\n" +
                                "    sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü taraflardan elde edilerek, kaydedilerek, depolanarak ve\n" +
                                "    güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat işleme şartına\n" +
                                "    dayanılarak işlenecektir.\n" +
                                "</p>\n" +
                                "\n" +
                                "<p>\n" +
                                "    SİTE' ye üye olmak için kullandığınız verilerden başlayarak üye olarak gönderdiğiniz ve aldığınız mesajlar, şifre ve\n" +
                                "    parola bilgileri, mesajın gönderilme bilgileri, etkileşimde bulunduğunuz içerik ve konular, SİTE' yle yaptığınız\n" +
                                "    yazışmalar, kullandığınız uygulamalar, ilgilendiğiniz reklamlar ve marka dahil reklam içerikleri, SİTE' de geçirdiğiniz\n" +
                                "    zaman, saat dilimi, süresi ve ziyaret sıklığı, kullandığınız cihaz / cihazlar, \"bot\" hesap olup olmadığına ilişkin\n" +
                                "    değerlendirmeye yarayacak bilgiler, bağlı olduğunuz ağ, bulunduğunuz bölge vb. kişisel verileriniz işlenebilmektedir.\n" +
                                "</p>\n" +
                                "\n" +
                                "\n" +
                                "<p>\n" +
                                "    Bizimle paylaştığınız kişisel verileriniz sadece analiz yapmak suretiyle ve üyenin açık rızasıyla; sunduğumuz hizmetlerin gerekliliklerini en iyi şekilde yerine getirebilmek, bu hizmetlere sizin tarafınızdan ulaşılabilmesini ve maksimum düzeyde \n" +
                                "    faydalanılabilmesini sağlamak, sorunları gidermek, SİTE güvenliğini sağlamak, SİTE' nin iş faaliyetlerinin yürütülmesi\n" +
                                "    ve buna bağlı denetim; hizmetlerimizi, ihtiyaçlarınız doğrultusunda geliştirebilmek ve sizleri daha geniş kapsamlı\n" +
                                "    hizmet sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek ve kanundan doğan zorunlulukların (kişisel\n" +
                                "    verilerin talep halinde adli ve idari makamlarla paylaşılması) yerine getirilebilmesi amacıyla, sözleşme ve hizmet\n" +
                                "    süresince, amacına uygun ve ölçülü bir şekilde işlenecek ve güncellenecektir.\n" +
                                "</p>",
                            icon: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#118c11",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Evet",
                            cancelButtonText: "Hayır",
                          }).then(async (result) => {
                            const element = document.getElementById(
                                event.target.id,
                            );
                            if (result.isConfirmed) {
                              element.checked = true;
                              setRegisterFormIsValid((prev) => ({
                                ...prev,
                                gizlilikSozlesme: {status: true, message: ""},
                              }));
                              setFormInput((prev) => ({
                                ...prev,
                                gizlilikSozlesme: true,
                              }));
                            } else {
                              element.checked = false;
                              setFormInput((prev) => ({
                                ...prev,
                                gizlilikSozlesme: false,
                              }));
                            }
                          });
                        },
                      }}
                  />
                </div>
              </Fragment>
          ) : (
              <Fragment>
                <p>Email adresine gönderilen doğrulama kodunu giriniz.</p>
                <Input
                    label="Doğrulama Kodu"
                    type="input"
                    className={classes.input}
                    validation={formInput?.otp != null && verifyFormIsValid.otp}
                    input={{
                      id: "otp",
                      type: "text",
                      placeholder: "Doğrulama Kodu",
                      value:
                          formInput != null && formInput.otp != null
                              ? formInput.otp
                              : "",
                      onChange: (event) => {
                        setVerifyFormIsValid((prev) => ({
                          ...prev,
                          otp: Validation("Doğrulama Kodu", event.target.value, {
                            isRequired: true,
                            type: 1,
                            min: 6,
                            max: 6,
                          }),
                        }));
                        setFormInput((prev) => ({
                          ...prev,
                          otp: event.target.value,
                        }));
                      },
                    }}
                />
              </Fragment>
          )}

          <div className={classes.bottomBtnBox}>
            <button type="submit" className={classes.loginBtn}>
              {title}
            </button>
            {formType === 3 && (
                <button
                    type="button"
                    className={classes.resendBtn}
                    onClick={async (event) => {
                      event.preventDefault();
                      await ResendEmailOtp();
                    }}
                >
                  Kodu Tekrar Gönder
                </button>
            )}
            {formType !== 0 && formType !== 3 && (
                <button
                    type="button"
                    onClick={() => {
                      setFormType(0);
                      setTitle("Giriş Yap");
                    }}
                >
                  Giriş Yap
                </button>
            )}
            {formType === 0 && (
                <button
                    type="button"
                    onClick={() => {
                      setFormType(2);
                      setTitle("Kayıt Ol");
                    }}
                >
                  Kayıt Ol
                </button>
            )}
          </div>
        </form>
      </div>
      <div className={classes.loginRight}>
        <img src="/assets/img/loginImage.png" alt=""/>
      </div>
    </div>
  );
};
