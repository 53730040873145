const requiretments = {
  isRequired: false,
  type: null,
  min: null,
  max: null,
  minVal: null,
  maxVal: null,
};

const validationType = [
  0, //onlyText
  1, //number
  2, //email
  3, //date
  4, //password
];

export const Validation = (label, value, requiretments) => {
  value = value.toString().replaceAll(" ", "");

  if (requiretments.isRequired) {
    if (value.length === 0 || value === "-undefined-undefined") {
      return {
        status: false,
        message: `${label}: Zorunludur.`,
      };
    }
  }
  if (requiretments.type !== undefined && requiretments.type != null) {
    switch (requiretments.type) {
      case 0:
        break;
      case 1:
        if (!/^\d+$/.test(value)) {
          return {
            status: false,
            message: `${label}: Sadece rakam girebilirsiniz.`,
          };
        }
        break;
      case 2:
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          return {
            status: false,
            message: `${label}: Geçersiz email adresi.`,
          };
        }
        break;
      case 3:
        const dateArr = value.split("-");
        const day = parseInt(dateArr[2]);
        const month = parseInt(dateArr[1]);
        const year = parseInt(dateArr[0]);
        if (year < 1940 || year > new Date().getFullYear() + 4) {
          return {
            status: false,
            message: `${label}: Geçersiz tarih.`,
          };
        }
        break;
      case 4:
        if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(value)) {
          return {
            status: false,
            message: `${label}: Gereksinimleri karşılamıyor. Şifreniz en az 8 karakter olmalı ve en az 1 büyük harf, 1 küçük harf, 1 rakam ve özel karakter içermelidir.`,
          };
        }
        break;
    }
  }
  if (requiretments.min !== null) {
    if (value.length < requiretments.min)
      return {
        status: false,
        message: `${label}: En az ${requiretments.min} karakter olmalı.`,
      };
  }
  if (requiretments.max !== null) {
    if (value.length > requiretments.max) {
      return {
        status: false,
        message: `${label}: En fazla ${requiretments.max} karakter olmalı.`,
      };
    }
  }
  if (requiretments.minVal != null) {
    if (value < requiretments.minVal) {
      return {
        status: false,
        message: `${label}: Girdiğiniz değer ${requiretments.minVal} değerinden küçük olamaz.`,
      };
    }
  }
  if (requiretments.maxVal != null) {
    if (value > requiretments.maxVal) {
      return {
        status: false,
        message: `${label}: Girdiğiniz değer ${requiretments.minVal} değerinden büyük olamaz.`,
      };
    }
  }

  return { status: true, message: "" };
};

export const CheckFormValid = (form) => {
  for (const element in form) {
    if (element !== null) {
      if (form[element].status === false) return form[element];
    }
  }
  return { status: true, message: "" };
};

export const FixDate = (value) => {
  const dateArr = value.split("-");
  return `${dateArr[0].slice(0, 4)}-${dateArr[1]}-${dateArr[2]}`;
};

export const CheckNotNullOrEmpty = (value) => {
  return value !== null && value !== "";
};
