import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { useLocation } from "react-router";
import classes from "./companyList.module.css";
import { Link } from "react-router-dom";
import { ScrollTop } from "../../../util/commonUtil";

const CompanyList = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    ScrollTop();
    GetCompanies();
  }, [state]);

  const GetCompanies = async () => {
    const response = await appContext.FetchHandler(
      "Company/ListFromCategory",
      "post",
      null,
      { id: state.id },
    );

    if (response.ok) {
      setCompanyData(response.data);
    }
  };

  return (
    <div className="pageContainer">
      <div className={classes.companyContainer}>
        <h1>{state && state.name}</h1>
        {companyData &&
          companyData.length > 0 &&
          companyData.map((x, i) => (
            <Link
              className={classes.companyBox}
              key={x.id}
              to={`/company/${x.name}/${x.id}`}
              state={x}
            >
              <div className={classes.companyBoxLeft}>
                <img src={x.mediaUrl} alt={x.name} />
              </div>
              <div className={classes.companyBoxRight}>
                <h2>{x.name}</h2>
                <hr />
                {/*<p>{x.shortDescription}</p>*/}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CompanyList;
