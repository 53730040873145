import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import { useLocation } from "react-router";
import classes from "./search.module.css";
import { Link } from "react-router-dom";
import { ScrollTop } from "../../../util/commonUtil";

export const Search = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    ScrollTop();
  }, [state]);

  return (
    <div className="pageContainer">
      <div className={classes.companyContainer}>
        {state && (
          <Fragment>
            <h1>"{state && state.name}" için arama sonuçları...</h1>
            {state.data.companies.length > 0 &&
              state.data.companies.map((x, i) => (
                <Link
                  className={classes.companyBox}
                  key={x.id}
                  to={`/company/${x.name}/${x.id}`}
                  state={x}
                >
                  <div className={classes.companyBoxLeft}>
                    <img src={x.mediaUrl} alt={x.name} />
                  </div>
                  <div className={classes.companyBoxRight}>
                    <h2>{x.name}</h2>
                    <hr />
                    <p>{x.shortDescription}</p>
                  </div>
                </Link>
              ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};
