import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./Profile.module.css";

import { AppContext } from "../../../context/app-context";

import { ScrollTop } from "../../../util/commonUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faPencil } from "@fortawesome/free-solid-svg-icons";
import CommentBox from "../../../components/client/commentBox/CommentBox";
import { ModalClient } from "../../../components/ui/modal/ModalClient";
import AccountForm from "../../../components/client/accountForm/AccountForm";
import { SwalSuccess, SwalSuccessMessage } from "../../../util/swalUtil";

export const Profile = () => {
  const appContext = useContext(AppContext);
  const [data, setData] = useState(null);
  const [tabData, setTabData] = useState({
    selected: 0,
    data: [],
  });
  const [formInput, setFormInput] = useState({
    phoneNumber: null,
    email: null,
    password: null,
    newPassword: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState({
    value: -1,
    label: "",
    url: "",
  });

  useEffect(() => {
    ScrollTop();
    GetProfileData();
  }, []);

  const GetProfileData = async () => {
    const response = await appContext.FetchHandler(
      "user/UserInfo",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setData(response.data);
      setTabData({ selected: 0, data: response.data.userComments });
    }
  };

  const SubmitHandler = async () => {
    const response = await appContext.FetchHandler(
      selectedForm.url,
      "post",
      "",
      formInput,
    );
    if (response.ok) {
      await GetProfileData();
      setShowModal(false);
      SwalSuccess();
    }
  };

  const TabHandler = (value) => {
    switch (value) {
      case 0:
        setTabData({ selected: 0, data: data.userComments });
        break;
      case 1:
        setTabData({ selected: 1, data: data.userLikes });
        break;
      case 2:
        setTabData({ selected: 2, data: data.userDisLikes });
        break;
    }
  };

  return (
    <div className="pageContainerColumn">
      {showModal && (
        <ModalClient
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${selectedForm.label} Güncelle`}
        >
          <AccountForm
            formInput={formInput}
            setFormInput={setFormInput}
            SubmitHandler={SubmitHandler}
            selectedForm={selectedForm}
            setShowModal={setShowModal}
          />
        </ModalClient>
      )}
      {data && (
        <Fragment>
          <h1>Profil</h1>
          <div className={classes.profile}>
            <div className={classes.left}>
              <div className={classes.userPic}>
                <img
                  src={
                    appContext.user.profilePicture
                      ? appContext.user.profilePicture
                      : "/assets/img/noProfile.png"
                  }
                  alt=""
                />
              </div>
              <h2>{data.fullName}</h2>
              <label
                onClick={() => {
                  setFormInput((prev) => ({
                    ...prev,
                    email: appContext.user.email,
                    password: null,
                  }));
                  setSelectedForm({
                    value: 1,
                    label: "Email",
                    url: "auth/changeEmail",
                  });
                  setShowModal(true);
                }}
              >
                {data.email}
                <FontAwesomeIcon
                  icon={faPencil}
                  style={{ fontSize: "1.2rem" }}
                />
              </label>

              <label
                onClick={() => {
                  setFormInput((prev) => ({
                    ...prev,
                    email: appContext.user.email,
                    phoneNumber: appContext.user.phoneNumber,
                    password: null,
                  }));
                  setSelectedForm({
                    value: 0,
                    label: "Cep Telefonu",
                    url: "auth/changePhone",
                  });
                  setShowModal(true);
                }}
              >
                {data.phoneNumber}
                <FontAwesomeIcon
                  icon={faPencil}
                  style={{ fontSize: "1.2rem" }}
                />
              </label>
              <label
                onClick={() => {
                  setFormInput((prev) => ({
                    ...prev,
                    email: appContext.user.email,
                    password: null,
                  }));
                  setSelectedForm({
                    value: 2,
                    label: "Şifre",
                    url: "auth/changePassword",
                  });
                  setShowModal(true);
                }}
              >
                ***************
                <span>Şifremi Değiştir</span>
              </label>
              <button
                className={classes.logoutBtn}
                onClick={() => appContext.LogoutHandler()}
              >
                Çıkış
              </button>
            </div>
            <div className={classes.right}>
              <div className={classes.tab}>
                <button
                  className={
                    tabData.selected === 0
                      ? classes.tabBtnActive
                      : classes.tabBtn
                  }
                  onClick={() => TabHandler(0)}
                >
                  Yorumlarım
                </button>
                <button
                  className={
                    tabData.selected === 1
                      ? classes.tabBtnActive
                      : classes.tabBtn
                  }
                  onClick={() => TabHandler(1)}
                >
                  Beğendiklerim
                </button>
                <button
                  className={
                    tabData.selected === 2
                      ? classes.tabBtnActive
                      : classes.tabBtn
                  }
                  onClick={() => TabHandler(2)}
                >
                  Beğenmediklerim
                </button>
              </div>
              {tabData.data.length > 0 ? (
                <CommentBox data={tabData.data} profile={true} />
              ) : (
                <p>Data bulunamadı</p>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
