import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";

const AccountForm = ({
  formInput,
  setFormInput,
  SubmitHandler,
  selectedForm,
  setShowModal,
}) => (
  <Fragment>
    <form
      id="form"
      className="form"
      onSubmit={async (event) => {
        event.preventDefault();
        await SubmitHandler();
      }}
    >
      {selectedForm.value === 0 ? (
        <div className="row">
          <Input
            label="Telefon"
            type="phoneNumber"
            onlyCountries={["tr"]}
            input={{
              id: "phoneNumber",
              type: "text",
              value: formInput.phoneNumber,
              required: "required",
              minLength: 13,
              maxLength: 13,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  phoneNumber: event,
                }));
              },
            }}
          />
          <Input
            label="Şifre"
            type="input"
            input={{
              id: "password",
              type: "password",
              required: "required",
              value:
                formInput != null && formInput.password != null
                  ? formInput.password
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
        </div>
      ) : selectedForm.value === 1 ? (
        <div className="row">
          <Input
            label="Email"
            type="input"
            input={{
              id: "email",
              type: "email",
              required: "required",
              value:
                formInput != null && formInput.email != null
                  ? formInput.email
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  email: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Şifre"
            type="input"
            input={{
              id: "password",
              type: "password",
              required: "required",
              value:
                formInput != null && formInput.password != null
                  ? formInput.password
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
        </div>
      ) : selectedForm.value === 2 ? (
        <div className="row">
          <Input
            label="Mevcut Şifre"
            type="input"
            input={{
              id: "password",
              type: "password",
              required: "required",
              value:
                formInput != null && formInput.password != null
                  ? formInput.password
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Yeni Şifre"
            type="input"
            input={{
              id: "newPassword",
              type: "password",
              required: "required",
              value:
                formInput != null && formInput.newPassword != null
                  ? formInput.newPassword
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  newPassword: event.target.value,
                }));
              },
            }}
          />
        </div>
      ) : null}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button type="submit" className="saveBtn">
          Güncelle
        </button>
        <button
          type="button"
          className="cancelBtn"
          onClick={() => setShowModal(false)}
        >
          İptal
        </button>
      </div>
    </form>
  </Fragment>
);

export default AccountForm;
