import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { AppContext } from "../../../context/app-context";

import classes from "./AdminHeader.module.css";
import { Link } from "react-router-dom";

export const AdminHeader = (props) => {
  const appContext = useContext(AppContext);
  const { collapseSidebar, toggleSidebar } = useProSidebar();

  const CollapsHandler = () => {
    collapseSidebar();
    props.setShowText(!props.showText);
    const contentBoxs = document.querySelectorAll(".menuItemContentBox");
    for (const item of contentBoxs) {
      if (props.showText) item.style.justifyContent = "center";
      else item.style.removeProperty("justify-content");
    }
  };

  return (
    <Fragment>
      <header className={classes.header}>
        {appContext.isMobile ? (
          <main style={{ display: "flex", padding: 10 }}>
            <div>
              <button
                className="sb-button"
                onClick={() => toggleSidebar()}
                style={{ color: "#fff" }}
              >
                <FontAwesomeIcon icon={faBars} style={{ fontSize: "1.5rem" }} />
              </button>
            </div>
          </main>
        ) : (
          <main style={{ display: "flex", padding: 10 }}>
            <button onClick={() => CollapsHandler()} style={{ color: "#fff" }}>
              <FontAwesomeIcon icon={faBars} style={{ fontSize: "1.5rem" }} />
            </button>
          </main>
        )}

        <div
          style={{ display: "flex", alignItems: "center", margin: "0 1rem" }}
        >
          <Link
            className={classes.buttonPreview}
            target="_blank"
            to={"/"}
          >
            Site
          </Link>
          <Link className={classes.buttonPreview} to={"/changePassword"}>
            Şifre Değiştir
          </Link>
          <button
            className={classes.buttonLogout}
            onClick={() => appContext.LogoutHandler()}
          >
            Çıkış
          </button>
        </div>
      </header>
    </Fragment>
  );
};
