import moment from "moment";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";

export const SliceText = (text, count) => {
  if (text.length > count) {
    text = text.slice(0, parseInt(count) - 3);
    text = `${text}...`;
  }
  return text;
};

export const RgbaConvert = (value, opacity) => {
  const red = parseInt(value.substring(1, 3), 16);
  const green = parseInt(value.substring(3, 5), 16);
  const blue = parseInt(value.substring(5, 7), 16);

  const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgba;
};

export const FormatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(90|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+90 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

export const ConvertFileBase64 = (file) => {
  return new Promise((resolve, rejest) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      // const base64 = reader.result;
      const preview = e.target.result;
      resolve({ base64, preview });
    };
  });
};

export const GetFileExtension = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
};

export const ScrollTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const ScrollModalAdminTop = () => {
  const modal = document.getElementById("modalAdmin");
  modal.scrollTop = 0;
};

export const FormatDateWithClock = (date) => {
  return moment(date).format("DD.MM.YYYY : HH:mm");
};

export const FormatDate = (date) => {
  return moment(date).format("DD.MM.YYYY");
};

export const ConvertCharset = (value) => {
  if (value == null) return "";
  const locales = ["tr", "TR", "tr-TR", "tr-u-co-search", "tr-x-turkish"];

  value = value
    .toString()
    .replaceAll("İ", "i")
    .toLocaleLowerCase(locales)
    .replaceAll("ç", "c")
    .replaceAll("ğ", "g")
    .replaceAll("ü", "u")
    .replaceAll("ı", "i")
    .replaceAll("ö", "o")
    .replaceAll("ş", "s");
  return value;
};

export const ConvertCurrency = (value) => {
  if (value === undefined || value.length === 0) return null;
  value = value.toLocaleString("tr-TR", { style: "currency", currency: "TRY" });
  return value;
};

export const DownloadExcel = (dataSource, sheetName, fileName) => {
  const ws = XLSX.utils.json_to_sheet(dataSource);
  const header = Object.keys(dataSource[0]);
  var wscols = [];
  for (let i = 0; i < header.length; i++) {
    wscols.push({ wch: header[i].length + 14 });
  }
  ws["!cols"] = wscols;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFileXLSX(
    wb,
    `${fileName}-${Math.floor(Math.random() * 99999)}.xlsx`,
  );
  return true;
};

export const DataTableProcess = (
  data,
  DeleteHandler,
  EditHandler,
  linkUrl,
  editText,
) => {
  return (
    <div className="dataTableProcess">
      {linkUrl ? (
        <Link
          className="dataTableProcessEdit"
          style={{ paddingBottom: "6px" }}
          to={linkUrl}
          state={data}
        >
          Düzenle
        </Link>
      ) : (
        <button
          className="dataTableProcessEdit"
          onClick={(data) => EditHandler(data)}
        >
          Düzenle
        </button>
      )}
      {DeleteHandler && (
        <button
          className="dataTableProcessDelete"
          id={data.id}
          onClick={(event) => DeleteHandler(event)}
        >
          Sil
        </button>
      )}
    </div>
  );
};

export const DataTableImage = (imgUrl) => {
  return (
    <img
      src={imgUrl}
      alt=""
      style={{
        width: "100%",
        height: "100px",
        objectFit: "contain",
        padding: ".5rem",
      }}
    />
  );
};

export const EntityStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusActive">Aktif</span>;
    case 1:
      return <span className="dataStatusPassive">Pasif</span>;
    case 2:
      return <span className="dataStatusPassive">Silinmis</span>;
    case 3:
      return <span className="dataStatusOrange">Onay Bekliyor</span>;
    case 4:
      return <span className="dataStatusPassive">Reddedildi</span>;
    default:
      break;
  }
};

export const ConfirmedBooleanHtml = (status) => {
  switch (status) {
    case true:
      return <span className="dataStatusActive">Onaylı</span>;
    case false:
      return <span className="dataStatusPassive">Onaysız</span>;

    default:
      break;
  }
};

export const ConfirmedBooleanData = () => {
  return [
    {
      value: true,
      label: "Onaylı",
    },
    {
      value: false,
      label: "Onaysız",
    },
  ];
};

export const MediaTypeHtml = (status) => {
  switch (status) {
    case 0:
      return <span>Fotoğraf</span>;
    case 1:
      return <span>Video</span>;

    default:
      break;
  }
};

export const MediaTypeData = () => {
  return [
    {
      value: "0",
      label: "Fotoğraf",
    },
    {
      value: "1",
      label: "Video",
    },
  ];
};

export const EntityStatusData = () => {
  return [
    {
      value: 0,
      text: "Aktif",
    },
    {
      value: 1,
      text: "Pasif",
    },
    {
      value: 2,
      text: "Silinmis",
    },
    {
      value: 3,
      text: "Onay Bekliyor",
    },
    {
      value: 4,
      text: "Reddedildi",
    },
  ];
};

export const UserTypeData = () => {
  return [
    {
      value: 0,
      label: "Normal Üye",
    },
    {
      value: 2,
      label: "Kurumsal Üye",
    },
  ];
};

export const UserTypeHtml = (value) => {
  switch (value) {
    case 0:
      return <span>Normal Üye</span>;
    case 2:
      return <span>Kurumsal Üye</span>;

    default:
      break;
  }
};
