import "./App.css";
import { Fragment, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/admin/dashboard/Dashboard";
import { AdminLayout } from "./layout/admin/layout/AdminLayout";
import { Layout } from "./layout/client/layout/Layout";
import { Category } from "./pages/admin/category/Category";
import { SubCategory } from "./pages/admin/category/SubCategory";
import { Slider } from "./pages/admin/slider/Slider";
import { Blog } from "./pages/admin/blog/Blog";
import { Company } from "./pages/admin/company/Company";
import Home from "./pages/client/home/Home";
import CompanyList from "./pages/client/companyList/CompanyList";
import CompanyDetail from "./pages/client/companyDetail/CompanyDetail";
import { CommentWaitingApprove } from "./pages/admin/comment/CommentWaitingApprove";
import { CommentRejected } from "./pages/admin/comment/CommentRejected";
import { CommentAdmin } from "./pages/admin/comment/CommentAdmin";
import { CompanyWaitingApprove } from "./pages/admin/company/CompanyWaitingApprove";
import { CompanyRejected } from "./pages/admin/company/CompanyRejected";
import { Profile } from "./pages/client/profile/Profile";
import { Search } from "./pages/client/search/Search";
import { UserList } from "./pages/admin/user/UserList";
import BlogDetail from "./pages/client/blog/BlogDetail";

function App() {
  // useEffect(() => {
  //     const google = document.createElement("link");
  //     const gRecap = document.createElement("script");
  //
  //     google.rel = "preconnect";
  //     google.href = "https://www.google.com";
  //     document.head.appendChild(google);
  //
  //     gRecap.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GRECAPKEY}`;
  //     document.body.appendChild(gRecap);
  // }, []);

  useEffect(() => {
    const gAnalytics = document.createElement("script");
    gAnalytics.async = true;
    gAnalytics.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GANALYTICS}`;

    const gAnalyticsCode = document.createElement("script");
    gAnalyticsCode.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.REACT_APP_GANALYTICS}');`;

    document.body.appendChild(gAnalytics);
    document.body.appendChild(gAnalyticsCode);
  }, []);
  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/search" element={<Search />} />
          <Route path="/companyList" element={<CompanyList />} />
          <Route path="/company/:name/:id" element={<CompanyDetail />} />
          <Route path="/blog/:name/:id" element={<BlogDetail />} />
        </Route>
        <Route exact path="/admin" element={<AdminLayout />}>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/blog" element={<Blog />} />
          <Route path="/admin/userList" element={<UserList />} />
          <Route path="/admin/slider" element={<Slider />} />
          <Route path="/admin/category" element={<Category />} />
          <Route path="/admin/category/subCategory" element={<SubCategory />} />
          <Route path="/admin/company/allCompany" element={<Company />} />
          <Route
            path="/admin/company/waitingApprove"
            element={<CompanyWaitingApprove />}
          />
          <Route path="/admin/company/rejected" element={<CompanyRejected />} />
          <Route
            path="/admin/comment/waitingApprove"
            element={<CommentWaitingApprove />}
          />
          <Route path="/admin/comment/rejected" element={<CommentRejected />} />
          <Route path="/admin/comment/allComment" element={<CommentAdmin />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
