import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { AppContext } from "./app-context";
import {
  SwalErrorMessage,
  SwalErrorResponse,
  SwalInfoResponse,
} from "../util/swalUtil";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const AppContextProvider = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [token, setToken] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [user, setUser] = useState(null);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const IsMobileHandler = () => {
    //991.98px

    if (window.matchMedia("(max-width: 1199.98px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {}, [user]);

  useEffect(() => {
    CheckLogin();
    IsMobileHandler();
  }, []);

  const GetHeaders = (contentType, pageType) => {
    switch (contentType) {
      case "formData":
        return {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          AppName: pageType === "admin" ? "admin" : "client",
        };
      default:
        return {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          AppName: pageType === "admin" ? "admin" : "client",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        };
    }
  };

  const CheckLogin = () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      setIsLoggedIn(true);
      ParseJwt(token);
      setToken(token);
    }
  };

  const IsLoggedInAsync = async () => {
    const token = localStorage.getItem("token");
    return token != null;
  };

  function ParseJwt(token) {
    const data = JSON.parse(
      Buffer.from(token.split(".")[1], "base64").toString(),
    );

    if (data !== "undefined" && data != null) {
      setUser(data);
      if (data.roles.includes("ADMIN")) setIsAdmin(true);
    }
  }

  const LoginHandler = async (data, isAdmin) => {
    const response = await FetchHandler("Auth/Login", "post", null, data);
    if (response != null && response.ok) {
      ClearUserData();
      setToken(response.data);
      ParseJwt(response.data);
      localStorage.setItem("token", response.data);
      setIsLoggedIn(true);
      !isAdmin && window.location.replace("/");
    } else if (response?.statusCode === 401) {
      return response;
    } else {
      return response;
    }
  };

  const LoginRecaptchaHandler = async (data, isAdmin) => {
    const res = GetGRecapToken().then(async (gToken) => {
      data.gToken = gToken;
      const response = await FetchHandler(
        isAdmin ? "Auth/AdminLogin" : "Auth/Login",
        "post",
        null,
        data,
      );
      if (response.ok) {
        ClearUserData();
        setToken(response.data);
        ParseJwt(response.data);
        localStorage.setItem("token", response.data);
        setIsLoggedIn(true);
        !isAdmin && window.location.replace("/");
      } else if (response.statusCode === 401) {
        return response;
      } else {
        return response;
      }
    });
    return await res;
  };

  const VerifyPhone = async (data) => {
    const res = GetGRecapToken().then(async (gToken) => {
      data.gToken = gToken;
      const response = await FetchHandler(
        "Auth/VerifyPhone",
        "post",
        null,
        data,
      );
      if (response.ok) {
        setToken(response.data);
        ParseJwt(response.data);
        localStorage.setItem("token", response.data);
        setIsLoggedIn(true);
        window.location.replace("/");
      } else {
        return response;
      }
    });
    return await res;
  };

  const LogoutHandler = () => {
    ClearUserData();
    window.location.replace("/");
  };

  const ClearUserData = () => {
    localStorage.removeItem("token");
    setToken(null);
    setIsLoggedIn(false);
  };

  const FetchHandler = async (url, method, contentType, data, pageType) => {
    setLoader(true);
    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: method,
      mode: "cors",
      headers: GetHeaders(contentType, pageType),
      body:
        method === "get"
          ? null
          : contentType === "formData"
            ? data
            : JSON.stringify(data),
    })
      .then(async (response) => {
        setLoader(false);

        if (response.status === 401 || response.status === 403) {
          Swal.fire({
            title: "Hata",
            text: "Yetkiniz bulunmamaktadır.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Tamam",
          }).then(async (result) => {
            LogoutHandler();
          });
          return false;
        }

        const responseData = await response.json();
        if (responseData !== undefined) {
          if (responseData.ok) return responseData;
          else if (responseData.statusCode === 406) {
            SwalInfoResponse(responseData);
            return responseData;
          } else {
            SwalErrorResponse(responseData);
            return responseData;
          }
        } else {
          SwalErrorMessage(`${response.status} ${response.statusText}`);
          return response;
        }
      })
      .catch((error) => {
        setLoader(false);
        SwalErrorMessage("Serviste hata oluştu, üzerinde çalışıyoruz...");
        return error;
      });
  };

  const GetGRecapToken = () => {
    return new Promise((res, rej) => {
      try {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(`${process.env.REACT_APP_GRECAPKEY}`, { action: "submit" })
            .then(function (token) {
              return res(token);
            });
        });
      } catch (error) {
        window.location.replace("/");
      }
    });
  };

  useEffect(() => {
    const GetCategory = async () => {
      const response = await FetchHandler(
        `category/MainListWithFirstSub`,
        "get",
        null,
        null,
        null,
      );
      if (response.ok) {
        setCategoryData(response.data);
      }
    };
    GetCategory();
  }, []);

  useEffect(() => {
    const GetCompany = async () => {
      const response = await FetchHandler(`Company`, "get", null, null, null);
      if (response.ok) {
        setCompanyData(response.data);
      }
    };
    GetCompany();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isAdmin: isAdmin,
        isMobile: isMobile,
        loader: loader,
        token: token,
        setLoader: setLoader,
        user: user,
        categoryData: categoryData,
        companyData: companyData,
        IsLoggedInAsync: IsLoggedInAsync,
        LoginHandler: LoginHandler,
        LoginRecaptchaHandler: LoginRecaptchaHandler,
        LogoutHandler: LogoutHandler,
        FetchHandler: FetchHandler,
        GetGRecapToken: GetGRecapToken,
        VerifyPhone: VerifyPhone,
        showLoginModal: showLoginModal,
        setShowLoginModal: setShowLoginModal,
        showAddCompanyModal: showAddCompanyModal,
        setShowAddCompanyModal: setShowAddCompanyModal,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
