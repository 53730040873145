import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./LoginFormAdmin.module.css";
import { Validation } from "../../ui/input/Validation";

export const LoginFormAdmin = ({
  submitHandler,
  loginFormInput,
  setLoginFormInput,
  setFormType,
  loginFormInputIsValid,
  setLoginFormInputIsValid,
}) => {
  return (
    <Fragment>
      <form
        className={`form ${classes.loginForm}`}
        onSubmit={(event) => {
          event.preventDefault();
          submitHandler(loginFormInput);
        }}
      >
        <Input
          label="Email"
          type="input"
          style={{ width: "100%" }}
          validation={
            loginFormInputIsValid?.email != null && loginFormInputIsValid.email
          }
          input={{
            id: "email",
            type: "email",
            onChange: (event) => {
              setLoginFormInputIsValid((prev) => ({
                ...prev,
                email: Validation("Email", event.target.value, {
                  isRequired: true,
                  type: 2,
                }),
              }));
              setLoginFormInput((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            },
          }}
        />
        <div className={classes.forgotDiv}>
          <Input
            label="Şifre"
            type="input"
            style={{ width: "100%" }}
            validation={
              loginFormInputIsValid?.password != null &&
              loginFormInputIsValid.password
            }
            input={{
              id: "password",
              type: "password",

              onChange: (event) => {
                setLoginFormInputIsValid((prev) => ({
                  ...prev,
                  password: Validation("Şifre", event.target.value, {
                    isRequired: true,
                    type: 4,
                  }),
                }));
                setLoginFormInput((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              },
            }}
          />
          <button
            type="button"
            onClick={() => {
              setFormType(1);
            }}
          >
            Şifremi Unuttum?
          </button>
        </div>

        <button type="submit" className={classes.loginBtn}>
          Giriş
        </button>
      </form>
    </Fragment>
  );
};
