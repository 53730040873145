import React, { Fragment } from "react";
import { Input } from "../../ui/input/Input";
import classes from "./UserEditForm.module.css";
import {
  ConfirmedBooleanData,
  EntityStatusData,
  UserTypeData,
} from "../../../util/commonUtil";

export const UserEditForm = ({ formInput, setFormInput, submitHandler }) => {
  return (
    <Fragment>
      <form
        id="form"
        className={`form ${classes.form}`}
        onSubmit={submitHandler}
      >
        <div style={{ display: "flex" }}>
          <Input
            label="Durum"
            type="select"
            style={{ width: "100%" }}
            option={ConfirmedBooleanData()}
            input={{
              id: "emailConfirmed",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.emailConfirmed != null
                  ? formInput.emailConfirmed
                  : "",
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  emailConfirmed: event.target.value,
                }));
              },
            }}
          />
          <Input
            label="Kullanıcı Tipi"
            type="select"
            style={{ width: "100%" }}
            option={UserTypeData()}
            input={{
              id: "userType",
              type: "text",
              required: "required",
              value:
                formInput != null && formInput.userType != null
                  ? formInput.userType
                  : 0,
              onChange: (event) => {
                setFormInput((prev) => ({
                  ...prev,
                  userType: parseInt(event.target.value),
                }));
              },
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="btnSave">
            Kaydet
          </button>
        </div>
      </form>
    </Fragment>
  );
};
