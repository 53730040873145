import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./MostCommentCompany.module.css";
import { AppContext } from "../../../context/app-context";
import { Link } from "react-router-dom";

const MostCommentCompany = () => {
  const appContext = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetData();
  }, []);

  const GetData = async () => {
    const response = await appContext.FetchHandler(
      "company/MostComment",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setData(response.data);
    }
  };
  return (
    <Fragment>
      <div className={classes.main}>
        <div className={classes.mainTop}>
          <h2>En Çok Yorum Alan Firmalar</h2>
        </div>
        <div className={classes.itemContainer}>
          {data &&
            data.length > 0 &&
            data.map((x, i) => {
              return (
                <Link
                  className={classes.item}
                  key={x.id}
                  to={`/company/${x.name}/${x.id}`}
                  state={x}
                >
                  <img src={x.logoUrl} title={x.name} alt={x.name} />
                  <h3>{x.name}</h3>
                </Link>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};

export default MostCommentCompany;
