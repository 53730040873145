import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/app-context";
import {
  DataTableProcess,
  EntityStatusHtml,
  ScrollTop,
} from "../../../util/commonUtil";
import { SwallApprove, SwallDelete, SwalSuccess } from "../../../util/swalUtil";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { DatatableAdmin } from "../../../components/admin/datatable/DatatableAdmin";
import { CompanyCrudForm } from "../../../components/admin/companyCrudForm/CompanyCrudForm";

export const Company = () => {
  const appContext = useContext(AppContext);
  const pageTitle = "Şirket";
  const apiUrl = "company";
  const [crudState, setCrudState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [dataList, setDataList] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [userList, setUserList] = useState([]);
  const columns = [
    {
      name: "İşlemler",
      width: "200px",
      cell: (row) => {
        return (
          <div className="dataTableProcess">
            {DataTableProcess(
              row,
              (event) => DeleteHandler(event),
              () => EditHandler(row),
              null,
            )}
          </div>
        );
      },
    },
    {
      name: "Durum",
      selector: (row) => EntityStatusHtml(row.entityStatus),
      sortable: true,
      width: "150px",
    },
    {
      name: "Ad",
      selector: (row) => row.name,
      filterName: "name",
      sortable: true,
      width: "auto",
    },

    {
      name: "Sıra",
      selector: (row) => row.index,
      sortable: true,
      width: "100px",
    },
  ];

  const EditHandler = (data) => {
    ScrollTop();
    setFormInput(data);
    setShowModal(true);
  };

  useEffect(() => {
    const GetDataList = async () => {
      const response = await appContext.FetchHandler(
        `${apiUrl}`,
        "get",
        null,
        null,
        "admin",
      );

      if (response.ok) {
        setDataList(response.data);
      }
    };
    GetDataList();
  }, [crudState]);

  useEffect(() => {
    const GetCategoryList = async () => {
      const response = await appContext.FetchHandler(
        `Category/AllActive`,
        "get",
        null,
        null,
        "admin",
      );
      if (response.ok) {
        const _tempData = [];
        for (const item of response.data) {
          _tempData.push({ value: item.id, label: item.name });
        }
        setCategoryList(_tempData);
      }
    };
    const GetUserList = async () => {
      const response = await appContext.FetchHandler(
        `User/CompanyUserList`,
        "get",
        null,
        null,
        "admin",
      );

      if (response.ok) {
        const _tempData = [];
        for (const item of response.data) {
          _tempData.push({ value: item.id, label: item.fullName });
        }
        setUserList(_tempData);
      }
    };
    GetCategoryList();
    GetUserList();
  }, []);

  const SubmitHandler = async (event) => {
    event.preventDefault();

    await SwallApprove().then(async (res) => {
      const formData = new FormData(document.getElementById("form"));
      if (formInput.id !== undefined) formData.append("id", formInput.id);

      formData.delete("category");
      formInput.category.forEach((x, i) => {
        formData.append(`category[${i}][value]`, x.value);
        formData.append(`category[${i}][label]`, x.label);
      });

      if (formInput.appUser !== undefined && formInput.appUser?.value != null) {
        formData.delete("appUser");
        formData.append("appUser[value]", formInput?.appUser?.value);
        formData.append("appUser[label]", formInput?.appUser?.label);
      }

      if (res.isConfirmed) {
        const method = formInput.id ? "put" : "post";
        const response = await appContext.FetchHandler(
          `${apiUrl}`,
          method,
          "formData",
          formData,
        );

        if (response.ok) {
          setCrudState(!crudState);
          event.target.reset();
          setShowModal(false);
          SwalSuccess();
        }
      }
    });
  };

  const DeleteHandler = async (event) => {
    event.preventDefault();

    await SwallDelete().then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          `${apiUrl}`,
          "delete",
          null,
          { id: event.target.id },
        );

        if (response.ok) {
          setCrudState(!crudState);
          SwalSuccess();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="titleBox">
        <button
          type="button"
          className="btnCreate"
          onClick={() => {
            setFormInput({});
            setShowModal(true);
          }}
        >
          {pageTitle} Ekle
        </button>
        <h3>{pageTitle}</h3>
      </div>
      {showModal && (
        <ModalAdmin
          showModal={showModal}
          setShowModal={setShowModal}
          title={`${pageTitle} Ekle `}
        >
          <CompanyCrudForm
            formInput={formInput}
            setFormInput={setFormInput}
            submitHandler={SubmitHandler}
            setShowModal={setShowModal}
            isMedia={true}
            hideShort={false}
            categoryList={categoryList}
            userList={userList}
          />
        </ModalAdmin>
      )}
      {dataList && (
        <div>
          <DatatableAdmin data={dataList} columns={columns} />
        </div>
      )}
    </Fragment>
  );
};
