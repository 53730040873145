import React, { Fragment, useContext } from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/app-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  const appContext = useContext(AppContext);
  return (
    <footer className={classes.footer}>
      <div className={classes.main}>
        <div className={classes.mainLogo}>
          <Link to="/">
            <img src="/assets/img/logoGray.png" alt="logo" />
          </Link>
        </div>
        <div className={classes.mainCategoryBox}>
          {appContext.categoryData.map((x, i) => (
            <div className={classes.mainCategory} key={x.id}>
              <ul>
                <li>
                  <Link to="/companyList" state={x}>
                    {x.name}
                  </Link>
                </li>
                {x.subCategories.map((y, j) => (
                  <li key={y.id}>
                    <Link to="/companyList" state={y}>
                      {y.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className={classes.mainCategory}>
            <ul>
              {/*<li>Diğer</li>*/}
              {/*<li>*/}
              {/*  <Link to="/hakkimizda">Hakkımızda</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/blog">Blog</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/iletisim">İletişim</Link>*/}
              {/*</li>*/}
              <div className={classes.socialBox}>
                <Link to="/">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Link>
                <Link
                  to="https://www.instagram.com/memnunsancom/"
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
              </div>
              <div className={classes.socialBox}>
                <Link to="/">
                  <FontAwesomeIcon icon={faXTwitter} />
                </Link>
                <Link to="/">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </Link>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        Copyright &copy; {new Date().getFullYear()} memnunsan
      </div>
    </footer>
  );
};
