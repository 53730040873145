import React, { useContext, useEffect, useState } from "react";
import classes from "./Home.module.css";
import MainSlider from "../../../components/client/mainSlider/MainSlider";
import { AppContext } from "../../../context/app-context";
import CommentBox from "../../../components/client/commentBox/CommentBox";
import { Link } from "react-router-dom";
import MostCommentCompany from "../../../components/client/mostCommentCompany/MostCommentCompany";
import { ScrollTop } from "../../../util/commonUtil";
import BlogWidget from "../../../components/client/blogWidget/BlogWidget";

const Home = () => {
  const appContext = useContext(AppContext);
  const [sliderData, setSliderData] = useState([]);
  const [lastComments, setLastComments] = useState([]);
  const [bestComments, setBestComments] = useState([]);
  const [lastBlog, setLastBlog] = useState([]);

  useEffect(() => {
    ScrollTop();
    GetSliderData();
    GetLastComments();
    GetBestComments();
    GetLastBlogs();
  }, []);
  const GetSliderData = async () => {
    const response = await appContext.FetchHandler("slider", "get", null, null);

    if (response.ok) {
      setSliderData(response.data);
    }
  };

  const GetLastComments = async () => {
    const response = await appContext.FetchHandler(
      "comment/lastComments",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setLastComments(response.data);
    }
  };

  const GetBestComments = async () => {
    const response = await appContext.FetchHandler(
      "comment/bestComments",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setBestComments(response.data);
    }
  };
  const GetLastBlogs = async () => {
    const response = await appContext.FetchHandler("blog", "get", null, null);

    if (response.ok) {
      setLastBlog(response.data);
    }
  };

  return (
    <div className="pageContainerColumn">
      <MainSlider data={sliderData} />
      {lastComments && (
        <div className={classes.comments}>
          <div className={classes.commentsTop}>
            <h2>En Son Yorumlar</h2>
            {/*<Link to="/"> Daha Fazla</Link>*/}
          </div>
          <div className={classes.commentBottom}>
            <CommentBox widget={true} data={lastComments} />
          </div>
        </div>
      )}
      <MostCommentCompany />
      {bestComments && (
        <div className={classes.comments}>
          <div className={classes.commentsTop}>
            <h2>En İyi Yorumlar</h2>
            {/*<Link to="/"> Daha Fazla</Link>*/}
          </div>
          <div className={classes.commentBottom}>
            <CommentBox widget={true} data={bestComments} />
          </div>
        </div>
      )}
      {lastBlog && lastBlog.length > 0 && <BlogWidget data={lastBlog} />}
    </div>
  );
};

export default Home;
